import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's snow theme CSS
import './editor.scss'

class RichTextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = { text: '' };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(text) {
    this.setState({ text });

    this.props.getFormattedValue(text)
  }

  componentDidMount() {
     this.setState({
          text: this?.props?.value 
     })
     }

  

  render() {
    return (
      <div>
        <h2 className='heading'>Additional Information</h2>
        <ReactQuill
          value={this.state.text}  
          onChange={this.handleChange}
          modules={RichTextEditor.modules}
          formats={RichTextEditor.formats}
          className='textEditorClass'
        />
      </div>
    );
  }
}

// Quill modules and formats configuration
RichTextEditor.modules = {
  toolbar: [
    [{ 'size': ['small','large', 'huge'] }],
    [{ 'header': '1' }, { 'header': '2' }],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    ['bold', 'italic', 'underline'],
    [{ 'align': [] }],
    ['clean']
  ],
};

RichTextEditor.formats = [
  'header','font', 'size',
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet',
  'align',
  'link'
];

export default RichTextEditor;