import AddDataComponent from "components/common/AddDataComponent";
import React from "react";

const GlobalAdd = () =>{
return(
<React.Fragment>
    <AddDataComponent />
</React.Fragment>
);
}

export default GlobalAdd;
