import React from 'react';
import { Col,Row } from 'reactstrap';
import LoginForm from '../views/LoginForm';
import '../assets/loginpage/loginpage.css'
const LoginPage = () => {
    return (
        <React.Fragment>
            <div className='container-fluid main-container-login'>
                <Row className='d-flex align-items-center'>
                        <div className=''>
                        <LoginForm />
                        </div>
                    </Row>
            </div>
        </React.Fragment>
    );
}

export default LoginPage;