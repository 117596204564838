import { TailSpin } from "react-loader-spinner"



export const CLoader=(props)=>{
  const {color} = props;
  return (
    <div className="loader-div">
      <TailSpin   color={`white ${props.color}`} height={40} width={40}   radius="1"/>
    </div>
  )
}