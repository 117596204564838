import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
import InputBox from "components/Input/InputBox";
import Button from "components/Button/Button";
import { API } from "utils/API/API";
import { SketchPicker } from "react-color";
import { toast } from "react-toastify";
import "../assets/addproductform.scss";

const {
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardTitle,
} = require("reactstrap");

const ManageColorForm = () => {
  const { handleSubmit, control, setValue, getValues } = useForm({});
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const id = location?.search?.split("?")[1];
  const [sketchCode, setSketchCode] = useState("");
  const [sketchCode2, setSketchCode2] = useState("");
  const [isColor, setIsColor] = useState(false);
  const [isColor2, setIsColor2] = useState(false);

  const onSubmit = (data) => {
    if (data.name === undefined || data.name === "" || sketchCode === "") {
      toast.error("Please fill at least one color with name.");
    } else {
      setIsLoading(true);
      data["code"] = sketchCode;
      data["code2"] = sketchCode2;

      if (id === undefined) {
        addColor(data);
      } else {
        editColor(data);
      }
    }
  };

  const addColor = async (data) => {
    try {
      const response = await API.addColor(data);
      if (response.success) {
        toast.success(response.message);
        history.goBack();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const editColor = async (data) => {
    try {
      const response = await API.editColorList(data, id);
      if (response.success) {
        toast.success(response.message);
        history.goBack();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) getData();
  }, [id]);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await API.getColorDetails(id);
      if (response?.success) {
        const data = response?.data;
        setValue("name", data?.name);
        setValue("code", data?.code);
        setValue("code2", data?.code2);
        setSketchCode(data?.code);
        setSketchCode2(data?.code2)
      } else {
        toast.error(response?.message);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };
  const handleCancelClick = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const colorCodechange = (color) => {
    setSketchCode(color.hex);
    // setIsColor(false)
  };

  const colorCodechange2 = (color) => {
    setSketchCode2(color.hex);
    // setIsColor(false)
  };

  console.log("sketch 1", sketchCode);
  console.log("sketch 2", sketchCode2);

  return (
    <React.Fragment>
      {loading ? (
        <div className="loadercss ">
          <ClipLoader color={"black"} loading={loading} size={50} />{" "}
        </div>
      ) : (
        <div className="content d-flex align-items-center">
          <div className="container">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md="3"></Col>
                <Col md="6" sm="12">
                  <div className="addcomponent">
                    <Card className="p-3">
                      <CardHeader className="p-0">
                        <CardTitle tag="h5" className="ml-3">
                          {id !== undefined ? "Edit Color" : "Add Color"}
                        </CardTitle>
                      </CardHeader>

                      <Col className="pr-1" md="12">
                        <Row>
                          <Col md="12">
                            <InputBox
                              placeholder="Color Name"
                              type="text"
                              label={"Color Name"}
                              name="name"
                              control={control}
                            />
                            {/* <InputBox
                            label={'Color'}
                            type="text"
                            name="code"
                            control={control}
                          /> */}
                            <div className="colorPicker">
                              {sketchCode2 ? (
                                <div   className="dualColorContainerr">
                                  <div style={{ backgroundColor: `${sketchCode !== '' ? sketchCode : ""}` }} className="colorInner">

                                  </div>
                                  <div style={{ backgroundColor: `${sketchCode2 !== '' ? sketchCode2 : ""}` }} className="colorInner2">

                                  </div>
                              </div>
                              ) : (
                                <div
                                  className="colorContainerr"
                                  style={{
                                    backgroundColor: `${
                                      sketchCode !== "" ? sketchCode : ""
                                    }`,
                                  }}
                                ></div>
                              )}
                              <div className="btns">
                                <Button
                                  label={
                                    !isColor ? "Choose color" : "Submit color"
                                  }
                                  type="reset"
                                  onClick={() => {
                                    setIsColor2(false)
                                    setIsColor(!isColor)
                                  }}
                                />
                                <Button
                                  label={
                                    !isColor2
                                      ? "Choose color 2"
                                      : "Submit color 2"
                                  }
                                  type="reset"
                                  onClick={() => {
                                    setIsColor(false)
                                    setIsColor2(!isColor2)
                                  }}
                                />
                              </div>
                              {isColor && (
                                <div className="picker">
                                  <SketchPicker
                                    disableAlpha
                                    color={sketchCode}
                                    onChange={(colors) =>
                                      colorCodechange(colors)
                                    }
                                  />
                                </div>
                              )}

                              {isColor2 && (
                                <div className="picker">
                                  <SketchPicker
                                    disableAlpha
                                    color={sketchCode2}
                                    onChange={(colors) =>
                                      colorCodechange2(colors)
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Row className="align-items-center my-3 p-3">
                        <Col md="6" sm="6">
                          <div className="">
                            <Button label="SUBMIT" isLoading={isLoading} />
                          </div>
                        </Col>
                        <Col md="6" sm="6">
                          <div className="text-right">
                            <Button
                              label="CANCEL"
                              onClick={handleCancelClick}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                </Col>
                <Col md="3"></Col>
              </Row>
            </Form>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ManageColorForm;
