import Dashboard from "views/Dashboard.js";
import TableList from "views/Tables.js";
import GlobalAdd from "views/GlobalAdd";
import AddProductTable from "views/AddProductTable";
import AddProductForm from "views/AddProductForm";
import ManageUsers from "views/manageuser";
import ManageUserForm from "views/GlobalMangeUserForm";
import ManageTags from "views/ManageTags";
import ManageTagForm from "components/common/ManageTagForm";
import EditTitle from "views/editTitle";
import MangeColors from "views/manageColors";
import ManageColorForm from "views/manageColorForm";
import EditTitleForm from "views/EditTitleform";
import MangeFields from "views/manageFields";
import EditFieldForm from "views/editFieldForn";
import ManageUserProjects from "views/manageUserProjects";


var routes = [
  {
    id: 1,
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin",
    showOption: true,
    level: null
  },
  {
    id: 3,
    path: "/manufacturer",
    name: "Manufacturer",
    icon: "nc-icon nc-box-2",
    component: TableList,
    layout: "/admin",
    endpoint: '/manufacturer',
    showOption: true,
    level: 3,
    key:"manufacturer",
    keyId:1
  },

  {
    id: 2,
    path: "/curated_selection",
    name: "Segment",
    icon: "nc-icon nc-tile-56",
    component: TableList,
    layout: "/admin",
    endpoint: '/curated_selection',
    showOption: true,
    level: 3,
    key:"segment",
    keyId:2
  },
  {
    id: 4,
    path: "/building_type",
    name: "Building Type",
    icon: "nc-icon nc-istanbul",
    component: TableList,
    layout: "/admin",
    endpoint: '/building_type',
    showOption: true,
    level: 3,
    key:"building_type",
    keyId:6
  },
  {
    id: 5,
    path: "/product_type",
    name: "Product Type",
    icon: "nc-icon nc-tile-56",
    component: TableList,
    layout: "/admin",
    endpoint: '/product_type',
    showOption: true,
    level: 3,
    key:"product_type",
    keyId:4
  },
  {
    id: 6,
    path: "/schedule_type",
    name: "Schedule Type",
    icon: "nc-icon nc-layout-11",
    component: TableList,
    layout: "/admin",
    endpoint: '/schedule_type',
    showOption: true,
    level: 3,
    key:"schedule_type",
    keyId:7
  },
  {
    id: 7,
    path: "/product_category",
    name: "Product Category",
    icon: "nc-icon nc-basket",
    component: TableList,
    layout: "/admin",
    endpoint: '/product_category',
    showOption: true,
    level: 3,
    key:"product_category",
    keyId:3
  },
  {
    id: 8,
    path: "/accessible_complaint",
    name: "Accessible & Code",
    icon: "nc-icon nc-align-center",
    component: TableList,
    layout: "/admin",
    endpoint: "/accessible_complaint",
    showOption: true,
    level: 3,
    key:"accessible_code",
    keyId:5
  },
  {
    id: 11,
    path: "/gobaladd",
    name: "add",
    icon: "nc-icon nc-touch-id",
    component: GlobalAdd,
    layout: "/admin",
    showOption: false,
    level: null
  },
  {
    id: 10,
    path: "/gobaleedit/:id",
    name: "edit",
    icon: "nc-icon nc-touch-id",
    component: GlobalAdd,
    layout: "/admin",
    showOption: false,
    level: null
  },
  {
    id: 13,
    path: "/addproductform",
    name: "add",
    icon: "nc-icon nc-touch-id",
    component: AddProductForm,
    layout: "/admin",
    showOption: false,
    level: null
  },
  {
    id: 14,
    path: "/addproduct",
    name: "Manage Products",
    icon: "nc-icon nc-basket",
    component: AddProductTable,
    layout: "/admin",
    showOption: true,
    level: null
  },
  {
    id: 15,
    path: "/manage-tags",
    name: "Manage Tags",
    icon: "nc-icon nc-tag-content",
    component: ManageTags,
    endpoint: '/location-tag',
    layout: "/admin",
    showOption: true,
    level: null
  },
  {
    id: 16,
    path: "/tag",
    name: "updatetags",
    icon: "nc-icon nc-touch-id",
    component: ManageTagForm,
    layout: "/admin",
    showOption: false,
    level: null,
  },
  {
    id: 21,
    path: "/manageColorForm",
    name: "manageColorForm",
    icon: "nc-icon nc-touch-id",
    component: ManageColorForm,
    layout: "/admin",
    showOption: false,
    level: null,
  },

  {
    id: 19,
    path: "/edit-title",
    name: "Edit Title",
    icon: "nc-icon nc-ruler-pencil",
    component: EditTitle,
    layout: "/admin",
    showOption: true,
    level: null
  },
  {
    id: 24,
    path: "/editTitleForm/:id",
    name: "Edit Title Form",
    icon: "nc-icon nc-ruler-pencil",
    component: EditTitleForm,
    layout: "/admin",
    showOption: false,
    level: null
  },

  {
    id: 9,
    path: "/manageusers",
    name: "Manage Users",
    icon: "nc-icon nc-single-02",
    component: ManageUsers,
    layout: "/admin",
    showOption: true,
    level: null
  },
  {
    id: 20,
    path: "/managecolors",
    name: "Manage Colors",
    icon: "nc-icon nc-ruler-pencil",
    component: MangeColors,
    layout: "/admin",
    showOption: true,
    level: null
  },
  {
    id: 23,
    path: "/updateuserdetails",
    name: "update",
    icon: "nc-icon nc-touch-id",
    component: ManageUserForm,
    layout: "/admin",
    showOption: false,
    level: null,
  },
  {
    id: 12,
    path: "/gobaleedit/:id",
    name: "edit",
    icon: "nc-icon nc-touch-id",
    component: GlobalAdd,
    layout: "/admin",
    showOption: false,
    level: null
  },

  {
    id: 90,
    path: "/manageUserProjects/:id",
    name: "Manage",
    icon: "nc-icon nc-ruler-pencil",
    component: ManageUserProjects,
    layout: "/admin",
    showOption: false,
    level: null
  },

  {
    id: 49,
    path: "/manageFields",
    name: "Manage field",
    icon: "nc-icon nc-ruler-pencil",
    component: MangeFields,
    layout: "/admin",
    showOption: true,
    level: null
  },

  {
    id: 50,
    path: "/manageFieldsForm/:id",
    name: "Manage field Form",
    icon: "nc-icon nc-ruler-pencil",
    component: EditFieldForm,
    layout: "/admin",
    showOption: false,
    level: null
  },
  {
    id: 18,
    path: "/addproductform",
    name: "add",
    icon: "nc-icon nc-touch-id",
    component: AddProductForm,
    layout: "/admin",
    showOption: false,
    level: null,
    endpoint: "/addproductform",
  },

  {
    id: 17,
    path: "/",
    name: "Logout",
    icon: "nc-icon nc-button-power",
    component: "",
    layout: "",
    endpoint: "",
    showOption: true,
    level: null
  },



];
export default routes;
