import axios from "axios";
import { toast } from "react-toastify";
const AxiosInstance = axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: "http://api.planspace.ca/api/v1",
  // baseURL: 'http://192.168.0.79:4000/api/v1',
  baseURL: "http://ourappdemo.com:4000/api/v1",


  timeout: 2350000,
})

AxiosInstance.interceptors.response.use(response => {
  console.log('item',{response});
  return response
}, error => {
  if (error.response.status === 401) {
    localStorage.removeItem('mytoken')
    setTimeout(NavigateTimeout, 1500)


    toast.error("Your session is expried please login again.")
  
  }
  console.log('item',{error});
return error.response



  return Promise.reject()

}
)

const NavigateTimeout = () => {
  window.location.replace('/')

}

export default AxiosInstance