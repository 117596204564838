import React from "react";
import Button from "components/Button/Button";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ClipLoader } from "react-spinners";
import { CLoader } from "components/Loader/CLoader";
const ModalC = (props) => {
  return (
    <React.Fragment>
      <Modal isOpen={props?.show} toggle={props?.handleClose}>
        <ModalHeader>
          {props?.title ? props?.title : "Delete Record"}
        </ModalHeader>
        <ModalBody>
          <p className="text-center">
            {props?.message
              ? props?.message
              : "Do you want to delete this record?"}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={props?.handleClose} label="Cancel" />
          {!props.deleteLoader ? (
            <Button onClick={props?.handleYes} label="Yes" />
          ) : (
            <ClipLoader color={"black"} size={30} />
          )}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
export default ModalC;
