import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import "../../assets/css/Multi.css";

const MultiSelectManu = (props) => {
  const [options, setOptions] = useState(props.data);
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    // if(selected)
    props.onProductSelect(selected);
  }, [selected?.length]);

  useEffect(() => {
    // MapManufacturerSecond()
    mapData()

    mapResponseData()

  }, [props.data]);

  const mapResponseData = () =>{
    if(props?.reRender !== undefined){
      let resultt = []
    
      if(props?.data?.length !== 0){
        props?.data?.map((dt)=>{
          props?.reRender?.map((rR)=>{
            if(dt._id === rR?._id){
              resultt.push(rR)
            }
          })
        })
      }
    setSelected(resultt);

    }
    
    
  }

  const MapManufacturerSecond = () => {
    let ManuDatas = props?.data;
    console.log("your data data",props?.data)
    console.log("your data",props?.mapManu)
    console.log("props?.parentData",props?.parentData)
    let flarArray = []
    let result =
      props?.mapManu?.map((id) => {
        const data = ManuDatas && ManuDatas?.find((md) => 
         md?._id === id
        );
        console.log()
        return {
          ...data,
          label: data?.title,
          value: data?._id,
        };
      });
    setSelected(result);
    mapData();
  };



  const mapData = () => {
    
      let result = props?.data.map((me) => {
        return {
          ...me,
          label: me?.title,
          value: me?._id,
        };
      });
      setOptions([...result]);

  };
  let selectTitle = props.select;

  const customValueRenderer = (selected, _options) => {
    return selected.length ? (
      selected.map(({ title }) => (
        <span className="card-muli ">
          {" "}
          {title}{" "}
        </span>
      ))
    ) : (
      <span style={{ paddingTop: "7px" }}>{selectTitle}</span>
    );
  };

  const onSelectChange = (data) => {
    // MapManufacturerSecond()
    setSelected([...data]);
  };

  return (
    <div>
      <MultiSelect
        className="rmsc"
        options={options}
        value={selected}
        onChange={setSelected}
        labelledBy="Select"
        overrideStrings={"name"}
        valueRenderer={customValueRenderer}
      />
    </div>
  );
};

export default MultiSelectManu;
