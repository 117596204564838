import React, { useState } from 'react';
import InputBox from 'components/Input/InputBox';
import '../assets/loginform/loginform.css';
import { useHistory } from 'react-router-dom';
import logo from '../assets/img/mainlogo.png';
import {
    Card,
    CardHeader,
    CardTitle,
    Col,
    Row,
    Form,
} from 'reactstrap';
import Button from 'components/Button/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { RequiredMsg } from 'utils/Constants';
import { validateEmailRegex } from 'utils/Constants';
import { API } from 'utils/API/API';
import { CLoader } from 'components/Loader/CLoader';
import { toast } from 'react-toastify';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { useForm } from 'react-hook-form';

const schema = yup.object({
    email: yup.string().required().email(),
    password: yup.string().required(),
})
const LoginForm = () => {
    const history = useHistory();
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailMsg, setEmailMsg] = useState('')
    const [passwordMsg, setPasswordMsg] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const { doLogin, assignToken } = useAuthContext()

    const { handleSubmit, control, setValue, getValue } = useForm({

        resolver: yupResolver(schema)

    });


    const validateEmail = () => {
        if (email === "") {
            setEmailMsg(RequiredMsg)
            return false
        }
        else if (!validateEmailRegex(email)) {
            setEmailMsg("Email is not valid")
            return false
        }
        else {
            setEmailMsg("")
            return true
        }

    }


    const validatePassword = () => {
        if (password === "") {
            setPasswordMsg(RequiredMsg)
            return false
        }

        else {
            setPasswordMsg("")
            return true
        }

    }

    const handleLogin = async (data) => {


        // if (validateEmail() && validatePassword()) {
        setIsLoading(true)
        // let params = {
        //     email, password, userType: 'admin'
        // }
        data['userType'] = "admin"
        try {
            const response = await API.login(data)
            if (response.success) {
                let { data } = response
                localStorage.setItem("mytoken", data)
                doLogin(true)
                assignToken(data)
                toast.success(response.message)

                history.replace("/admin/dashboard")
            }
            else {
                toast.error(response.message)
            }
        }
        catch (e) {
            // toast.error('Please provide valid login details')
            toast.error('Network Error')

        }
        finally {
            setIsLoading(false)
        }
    }
    // }



    return (
        <React.Fragment>
            <Row>
                <Col md="12" sm="12">
                    <Card className='p-3'>
                        <CardHeader className="p-0 card-header-login">
                            <CardTitle><img src={logo} alt='' /></CardTitle>
                        </CardHeader>
                        <CardTitle tag="h3" className="my-4" >Admin Login</CardTitle>

                        <Form onSubmit={handleSubmit(handleLogin)}>
                            <Row>
                                <Col md="12">
                                    <InputBox label="Email"
                                        type="text"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        error={emailMsg}
                                        name="email"
                                        control={control}
                                    />
                                </Col>
                                <Col md="12">
                                    <InputBox type="password"
                                        label="Password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        name="password"
                                        control={control}
                                    />
                                </Col>
                                <Col>
                                    <div className="mt-3">
                                        <Button
                                            type="submit"
                                            label="LOGIN"
                                            isLoading={isLoading}
                                        >
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </React.Fragment >
    );
}
export default LoginForm;