import React, { useEffect, useMemo, useRef, useState } from "react";
import { toast } from 'react-toastify';
import { API } from 'utils/API/API';
import './AddDataComponent.css';
import { useLocation } from 'react-router-dom';
import { useParams } from "react-router-dom";
import Button from "components/Button/Button";
import openeye from '../../assets/img/openeye.svg';
import closeeye from '../../assets/img/closeeye.svg';
import '../../stylesheets/manageform.scss';
import { CLoader } from 'components/Loader/CLoader';
// import { browserHistory } from 'react-router'
import { useHistory } from "react-router-dom";
import CSelect from "./CSelect";
import { ParentCategory } from "utils/Constants";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
import InputBox from "components/Input/InputBox";

const { Form, Row, Col, FormGroup, Input, Card, CardHeader, CardTitle } = require("reactstrap");


const schema = yup.object({
  name: yup.string().required("This field is required."),
})

const ManageTagForm = props => {

  const { handleSubmit, control, setValue, getValues, } = useForm({

    resolver: yupResolver(schema)

  });
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false)
  const endpoint = location.state?.apiPath;
  const level = location.state?.level;
  const id = location?.search?.split("?")[1]




  const onSubmit =  (data) => {
    setIsLoading(true)


    if(id===undefined)
    addTag(data)
    else 
    updateTag(data)
   
  }

  const addTag=async(data)=>{
    try {
      const response = await API.AddTableData(endpoint, data);
      if (response.success) {
        toast.success(response.message)
        history.goBack();
      }
      else {
        toast.error(response.message)
      }
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setIsLoading(false)
    }
  }
  
  
  const updateTag=async(data)=>{
    try {
      const response = await API.updateTag(endpoint,id, data);
      console.log(response)
      if (response.success) {
        toast.success(response.message)
        history.goBack();
      }
      else {
        toast.error(response.message)
      }
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setIsLoading(false)
    }
  }


  useEffect(() => {
    if (id)
      getData()
  }, [id]);


  const getData = async () => {
    setLoading(true);
    try {
      const response = await API.getData(endpoint, id);
      if (response.success) {
        const data = response.data
        setValue('name', data?.name)
      }
      else {
        toast.error(response.message)
      }
    }
    catch (e) {
      console.log(e)
    }
    finally {
      setLoading(false);
    }
  }



  const handleCancelClick = (e) => {
    e.preventDefault()
    history.goBack();
  }

  const handleEyeClick = () => {
    if (show) {
      setShow(false)
    } else {
      setShow(true)
    }
  }


  const statusList = [
    { id: 1, name: 'Active', value: 1 },
    { id: 2, name: 'In Active', value: 0 }
  ]

  const onError = (errors, e) => console.log(errors, e);

  return (
    <React.Fragment>
      {loading ? <div className="loadercss "><ClipLoader color={'black'} loading={loading} size={50} /> </div> : <div className="content d-flex align-items-center">
        <div className="container">
          <Form onSubmit={handleSubmit(onSubmit, onError)}>
            <Row >
              <Col md="3"></Col>
              <Col md="6" sm="12">
                <div className="addcomponent">
                  <Card className='p-3'>
                    <CardHeader className="p-0">
                      <CardTitle tag="h5" className='ml-3'>{id !== undefined ? "Edit Record" : "Add Record"}</CardTitle>
                    </CardHeader>

                    <Col className="pr-1" md="12">
                      <Row>
                        <Col md="12">
                          <InputBox
                            placeholder="Tag Name"
                            type="text"
                            label={'Tag Name'}
                            name="name"
                            control={control}
                          />
                        </Col>

                      </Row>

                    </Col>

                    <Row className="align-items-center my-3 p-3">
                      <Col md="6" sm="6">
                        <div className="">
                          <Button
                            label="SUBMIT"
                            isLoading={isLoading}
                          />
                        </div>
                      </Col>
                      <Col md="6" sm="6">
                        <div className="text-right">
                          <Button
                            label="CANCEL"
                            onClick={handleCancelClick}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </div>
              </Col>
              <Col md="3" ></Col>
            </Row>
          </Form>
        </div>
      </div>}
    </React.Fragment>   
  );

}

export default ManageTagForm;