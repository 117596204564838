import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ClipLoader } from "react-spinners";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import editLogo from "../assets/img/edit.png";
import deleteLogo from "../assets/img/delete.png";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { API } from "utils/API/API";
import { toast } from "react-toastify";
import "../assets/scss/editTitle.scss";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Button from "components/Button/Button";
import { useAuthContext } from "context/AuthContext/AuthContext";

const EditTitle = (props) => {
  const [loading, setLoading] = useState(false);
  const [isDragged, setIsDragged] = useState(false);
  const [indexUpdated, setIndexUpdated] = useState(false);
  const history = useHistory();
  const { SetFilterShow } = useAuthContext()

  const [filter, setFilter] = useState([]);

  useEffect(() => {
    getListing();
  }, []);

  const getListing = async () => {
    setLoading(true);
    try {
      let response = await API.getTitleListing();
      if (response?.success) {
        sortFilterByLevel(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  };

  const sortFilterByLevel = (data) => {
    let result = data?.sort((a, b) => a.levels - b.levels);
    setFilter(result);
  };

  const columns = [
    {
      name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Title</th>,
      selector: "name",
      cell: (row) => (
        <p className="mt-3 para">{row?.name ? row?.name : "N/A"}</p>
      ),
      sortable: true,
      sortField: "name",
    },

    {
      name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Status</th>,
      selector: "createdAt",
      cell: (row) => (
        <p className="mt-3 para">
          {row?.status ? (
            <span style={{ color: "green" }}>Active</span>
          ) : (
            <span style={{ color: "red" }}>In-Active</span>
          )}
        </p>
      ),
      sortable: true,
      sortField: "createdAt",
    },

    {
      name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Edit</th>,
      cell: (row) => (
        <img
          src={editLogo}
          alt=""
          onClick={(e) => handleEditClick(e, row?._id, row?.name, row?.status)}
          width="24px"
          height="24px"
        />
      ),
    },
  ];

  const handleEditClick = (e, id, names, stats) => {
    e.preventDefault();
    history.push({
      pathname: "/admin/editTitleForm/" + id,
      state: { name: names, status: stats },
    });
  };

  const onDragEnd = (e) => {
    setIsDragged(true);
    let sourceIndex = e.source.index;
    let destIndex = e.destination.index;

    let result = [];

    // Create a copy of the data array to avoid mutating the state directly
    const updatedData = [...filter];

    // Remove the dragged item from its original position
    const [draggedItem] = updatedData.splice(sourceIndex, 1);

    // Insert the dragged item at the destination position
    updatedData.splice(destIndex, 0, draggedItem);

    // Update the state with the new order of data
    setFilter([...updatedData]);

    console.log(updatedData);

    let updatedIndex = [];

    updatedData?.map((data, i) => {
      let prm = {
        id: data?._id,
        levels: i + 1,
      };
      updatedIndex.push(prm);
    });

    console.log(updatedIndex);

    setIndexUpdated(updatedIndex);
  };

  const handleUpdateIndex = async () => {
    let prm = {
      filters: indexUpdated,
    };

    let response = await API.updateTitleIndex(prm);
    if (response?.success) {
      toast.success(response?.message);
      setIsDragged(false);
      getListing();
      SetFilterShow(true)
    } else {
      toast.error(response?.message);
    }
  };

  return (
    <React.Fragment>
      <div className="content mb-5">
        <Row>
          <Col md="12">
            {loading ? (
              <div className="loadercss">
                <ClipLoader color="black" loading={loading} size={50} />
              </div>
            ) : (
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="6">
                      <CardTitle tag="h3">Edit Title</CardTitle>
                    </Col>
                    <Col md="6">
                      <div className="text-right mt-4 mr-4">
                        {isDragged && (
                          <Button
                            label="Update Index"
                            onClick={() => handleUpdateIndex()}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="tableForDragDrop my-4">
                  {/* <DataTable pagination
                                                  columns={columns}

                                                  data={filter}
                                                  
                                                  
                                             /> */}
                  <DragDropContext onDragEnd={(e) => onDragEnd(e)}>
                    <table>
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Status</th>
                          <th>Edit</th>
                        </tr>
                      </thead>

                      <Droppable
                        droppableId={"droppable"}
                        // index={index}
                      >
                        {(provided, snapshot) => (
                          <tbody
                            style={{ width: "100%" }}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {filter?.map((row, index) => (
                              <Draggable
                                key={row?._id}
                                draggableId={row?._id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <td>{row?.name ? row?.name : "N/A"}</td>
                                    <td>
                                      <p className="mt-3 para">
                                        {row?.status ? (
                                          <span style={{ color: "green" }}>
                                            Active
                                          </span>
                                        ) : (
                                          <span style={{ color: "red" }}>
                                            In-Active
                                          </span>
                                        )}
                                      </p>
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        <img
                                          src={editLogo}
                                          alt=""
                                          onClick={(e) =>
                                            handleEditClick(
                                              e,
                                              row?._id,
                                              row?.name,
                                              row?.status
                                            )
                                          }
                                          width="24px"
                                          height="24px"
                                        />
                                      }
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))}
                          </tbody>
                        )}
                      </Droppable>
                    </table>
                  </DragDropContext>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default EditTitle;
