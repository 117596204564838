import React, { useState } from "react";
import styles from "./pagination.module.scss";
import { DOTS, usePagination } from "./usePagination";

const Paginations = ({
  totalItem,
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  setPagePerSize,
  className,
  label,
}) => {
  const [pageDataSize, setpageDataSize] = useState(0);
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage =
    paginationRange && paginationRange[paginationRange?.length - 1];

  const onPageSize = (e) => {
    var values = e?.target?.value;
    setPagePerSize(parseInt(values));
  };

  let from = (currentPage - 1) * (pageSize ? pageSize : 10) + 1;

  let to = currentPage * (pageSize ? pageSize : 10);

  return (
    <div className={styles.pagination}>
      <span className={styles.pagination__text}>
        Showing{" "}
        {currentPage === 1 ? (totalCount === 0 ? "0" : "1") : from ? from : "0"}{" "}
        to {to > totalCount ? (totalCount ? totalCount : "0") : to ? to : "0"}{" "}
        of {totalCount ? totalCount : "0"} entries
      </span>
      <div className={styles.pagination__cta}>
        {currentPage === 1 ? (
          ""
        ) : (
          <button onClick={onPrevious} className={styles.prev}>
            <i className="fa fa-angle-left" aria-hidden="true"></i>
          </button>
        )}

        {console.log({paginationRange})}

        {paginationRange?.map((pageNumber) => {
          {
            /* if (pageNumber === DOTS) {
            return <li>&#8230;</li>;
          } */
          }

          return (
            <span
              onClick={() => {
               if(pageNumber !== "...")
               onPageChange(pageNumber)
              }}
              className={`${styles.number} ${
                pageNumber === currentPage ? styles.active : ""
              }`}
            >
              {pageNumber}
            </span>
          );
        })}

        {currentPage === lastPage
          ? ""
          : totalCount > 0 && (
              <button onClick={onNext} className={styles.next}>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </button>
            )}
      </div>
    </div>
  );
};
export default Paginations;
