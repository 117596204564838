import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { API } from "utils/API/API";
import { useHistory } from "react-router-dom";
import editLogo from "../assets/img/edit.png";
import deleteLogo from "../assets/img/delete.png";
import ClipLoader from "react-spinners/ClipLoader";
import "../assets/Table/table.css";
import { Table } from "reactstrap";
import "../components/Simple Table/SimpleTable.scss";
// import SimpleTable from "components/Simple Table/SimpleTable";
import Button from "components/Button/Button";
import ModalC from "components/common/ModalC";
import { Card, CardBody, Row, Col } from "reactstrap";
// import InputBox from "components/Input/InputBox";
import { toast } from "react-toastify";
import { TempChildData } from "utils/Constants";
import { nodeModuleNameResolver } from "typescript";
import SearchBox from "components/common/SearchBox";
import { useTitleContext } from "context/AuthContext/titleLIstContext";
import dropdown from "../assets/img/dropdown.png";
import { MdModeEditOutline } from "react-icons/md";
import { FaCheck } from "react-icons/fa";


function Tables(props) {
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("black");
  const [search, setSearch] = useState();
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [filter, setFilter] = useState([]);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [rowId, setRowId] = useState(null);
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const [expend, setExpend] = useState(true);
  const [childLength, setChildLength] = useState();
  const endpoint = location.state;
  const [titleList, setTitleList] = useState([]);
  const [endpointState, setEndpointState] = useState(endpoint);
  // const { titleArray } = useTitleContext();

  const [changePosition, setChangePosition] = useState(null);
  // const changePosition = useRef(null);
  const [positions, setPositions] = useState({});

  const [changePosition2, setChangePosition2] = useState(null);

  const id = location?.search?.split("?")[1];
  const inputRef = useRef(null);


  const handleAddClick = () => {
    history.push({
      pathname: "/admin/gobaladd",
      state: { apiPath: props.apiPath, level: props?.level },
    });
  };

  useEffect(() => {
    // console.log("obj",data)
    const result = data.filter((dataList) => {
      return dataList.title.toLowerCase().match(search.toLowerCase());
    });
    setFilter(result);
  }, [search]);

  useEffect(() => {
    console.log(props.apiPath, "api")
    if (props.apiPath)
      // fetchListingData()
      fetchListingDataWithChild();
  }, [props.apiPath]);

  useEffect(() => {
    getListing();
  }, []);

  const getListing = async () => {
    try {
      let response = await API.getTitleListing();
      if (response?.success) {
        console.log(response, "resp")
        setTitleList(response?.data);
        // mapTitleList(response?.data)
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      return error;
    }
  };



  const fetchListingDataWithChild = async () => {
    setLoading(true);
    try {
      const response = await API.fetchListingDataWithChild(props?.apiPath);
      const tableData = response.data;
      setData([...tableData]);
      setFilter([...tableData]);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (e, id) => {
    e.preventDefault();
    history.push({
      pathname: "/admin/gobaladd/",
      search: id,
      state: { apiPath: props.apiPath, level: props?.level },
    });
  };


  const handlePositionChange = (event, row) => {
    const newPosition = { ...positions, [row._id]: event.target.value };
    console.log("first", newPosition)
    setPositions(newPosition);
  };

  const onUpdatePosition = async (position, row, maxLen) => {
    if (positions[row._id] > maxLen || positions[row._id] < 1) {
      toast.error(`Please enter a position in the range of 1 to ${maxLen}`);
      setChangePosition(null);
    } else {
      setChangePosition(null);
      const filterId = row._id
      const updatedOrder = positions[row._id]
      const apiPath = props?.apiPath
      try {
        const response = await API.updateFilterOrder(apiPath, filterId, updatedOrder);
        if (response.success) {
          fetchListingDataWithChild();
          toast.success(response.message);
        } else toast.error(response.message);
      } catch (e) {
        toast.error("Network Error");
      }
    }

  }

  const SimpleDataTableChild = (data) => {
    let currentChild = data.data.child;

    const handleRowExpandToggle = (rowStatus, clickedRow) => {
      console.log("isFilter2", currentChild);
      const updatedIndex = currentChild.map((row, index) => {
        if (row._id === clickedRow._id) {
          return index;
        }
        return null;
      })
      const validIndexes = updatedIndex.filter(index => index !== null);
      const firstIndex = validIndexes[0];
      console.log('isIndex', firstIndex)
      currentChild[firstIndex].defaultExpanded = true;
      console.log("checktoggle4", rowStatus, clickedRow, currentChild);
    };

    console.log(currentChild, "dfdf2");
    if (currentChild === undefined) {
      console.log("dfdf2.2");
      return "";
    } else {
      return (
        <DataTable
          columns={columns3}
          data={currentChild}
          highlightOnHover
          expandableRowExpanded={row => row.defaultExpanded}
          expandableRowsComponent={SimpleDataTableSubChild}
          expandableRows
          onRowExpandToggled={(row, index) => handleRowExpandToggle(row, index)}
          customStyles={customStyles2}
          className="childtable"
          noDataComponent={null}
        />
      );
    }
  }

  const SimpleDataTableSubChild = (data) => {
    let currentChild = data.data.child;

    if (currentChild === undefined) {
      return "";
    } else {
      return (
        <table className="simpletable py-2">
          <tbody>
            {currentChild.map((child) => (
              <>
                {console.log("test", child)}
                <tr>
                  <td className="blank" style={{ width: '48px' }}></td>
                  <td className="titledata">
                    <p className="my-2">{`-${child.title}`}</p>
                  </td>
                  <td className="datedata">
                    <p className="my-2">
                      {moment(child.updatedAt).format("MM-DD-YYYY")}
                    </p>
                  </td>
                  <td className="statusdata">
                    {child.status === 1 ? (
                      <p className="my-2" style={{ color: "green" }}>
                        Active
                      </p>
                    ) : (
                      <p style={{ color: "red" }}>In Active</p>
                    )}
                  </td>
                  <td className="editdata">
                    {" "}
                    <img
                      src={editLogo}
                      alt=""
                      onClick={(e) => handleEditClick(e, child._id)}
                      width="24px"
                      height="24px"
                    />
                  </td>
                  <td className="deletedata">
                    {" "}
                    <img
                      src={deleteLogo}
                      alt=""
                      className="text-left "
                      onClick={(e) => handleDeleteClick(e, child._id)}
                      width="24px"
                      height="24px"
                    />{" "}
                  </td>

                  {/* <td>
                    <input
                      type="number"
                      readOnly={changePosition !== child._id}
                      value={positions[child._id]}
                      onChange={(e) => handlePositionChange(e, child)}
                      style={{
                        width: "60px"
                      }}
                      // min="1"
                      // max="5"
                      autoFocus
                    />
                    <img
                      src={changePosition === child._id ? dropdown : editLogo}
                      alt=""
                      className="text-left"
                      onClick={() => {
                        if (changePosition === child._id) {
                          onUpdatePosition(child.order, child);
                        } else {
                          setChangePosition(child._id);
                          // console.log("reff", changePosition.current);
                        }
                      }}
                      width="24px"
                      height="24px"
                    />
                  </td> */}
                  <td>
                    <div className={'customInputEditable'}>
                      <input
                        type="number"
                        readOnly={changePosition !== child._id}
                        value={positions[child._id]}
                        onChange={(e) => handlePositionChange(e, child)}
                        style={{ borderColor: changePosition === child._id && 'green' }}
                        // min="1"
                        // max="5"
                        autofocus
                        ref={inputRef}
                      />
                      <button onClick={() => {
                        if (changePosition === child._id) {
                          onUpdatePosition(child.order, child);
                        } else {
                          setChangePosition(child._id);
                          inputRef.current.focus();
                        }
                      }} style={{ color: changePosition === child._id && 'green' }}>
                        {changePosition === child._id ? <FaCheck /> : <MdModeEditOutline />}
                      </button>
                    </div>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      );
    }
  };

  const SimpleDataTable = (data) => {
    let currentChild = data.data.child;
    const handleRowExpandToggle = (rowStatus, clickedRow) => {
      console.log("isFilter2", currentChild);
      const updatedIndex = currentChild.map((row, index) => {
        if (row._id === clickedRow._id) {
          return index;
        }
        return null;
      })
      const validIndexes = updatedIndex.filter(index => index !== null);
      const firstIndex = validIndexes[0];
      console.log('isIndex', firstIndex)
      currentChild[firstIndex].defaultExpanded = true;
      console.log("checktoggle2", rowStatus, clickedRow, currentChild);
    };

    setChildLength(currentChild.length);
    console.log(currentChild, "dfdf");
    if (currentChild === undefined) {
      console.log("dfdf1.1");
      setExpend(false);
      return "";
    } else {
      return (
        <DataTable
          columns={columns2}
          data={currentChild}
          highlightOnHover
          expandableRowExpanded={row => row.defaultExpanded}
          expandableRowsComponent={SimpleDataTableChild}
          expandableRows
          onRowExpandToggled={(row, index) => handleRowExpandToggle(row, index)}
          customStyles={customStyles2}
          className="childtable"
          noDataComponent={null}
        />
      );
    }
  };

  const deleteData = async () => {
    setDeleteLoader(true);
    try {
      const response = await API.deleteData(props?.apiPath, rowId);
      if (response.success) {
        // fetchListingData()
        fetchListingDataWithChild();
        toast.success(response.message);
      } else toast.error(response.message);
    } catch (e) {
      toast.error("Network Error");
    } finally {
      setDeleteLoader(false);
      setShow(false);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleDeleteClick = (e, id) => {
    e.preventDefault();
    setRowId(id);
    setShow(true);
  };

  const handleYes = () => {
    setDeleteLoader(true);
  };

  const handleChange = (event) => {
    setSearch(event.target.value);
  };
  const customStyles = {
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        color: "black",
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // backgroundColor: 'black',
        // color:'white'
      },
    },
    cells: {
      style: {
        // backgroundColor: 'grey',
        // color:'white',
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const customStyles2 = {
    rdt_TableHead: {
      style: {
        display: "none",
      },
    },
    headCells: {
      style: {
        display: "none",
      },
    },
  };

  const columns = [
    {
      name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Title</th>,
      selector: "title",
      cell: (row) => <p className="mt-3 para">{row.title}</p>,
      sortable: true,
      sortField: "title",
    },
    {
      name: (
        <th style={{ fontSize: "15px", fontWeight: "600" }}>Date Created</th>
      ),
      selector: "updatedAt",
      cell: (row) => (
        <p className="mt-3 para">
          {moment(row.updatedAt).format("MM-DD-YYYY")}
        </p>
      ),
      sortable: true,
      sortField: "updatedAt",
    },
    {
      name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Status</th>,
      selector: "Status",
      cell: (d) => {
        return d.status === 1 ? (
          <p className="mt-3 para" style={{ color: "green" }}>
            Active
          </p>
        ) : (
          <p className="mt-3 para" style={{ color: "red" }}>
            In Active
          </p>
        );
      },
    },
    {
      name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Edit</th>,
      cell: (row) => (
        <img
          src={editLogo}
          alt=""
          onClick={(e) => handleEditClick(e, row._id)}
          width="24px"
          height="24px"
        />
      ),
    },
    {
      name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Delete</th>,
      cell: (row) => (
        <img
          src={deleteLogo}
          alt=""
          className="text-left "
          onClick={(e) => handleDeleteClick(e, row._id)}
          width="24px"
          height="24px"
        />
      ),
    },
    // {
    //   name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Position</th>,
    //   cell: (row, index) => (
    //     <>
    //       {console.log("ind", filter.length)}
    //       <input
    //         type="number"
    //         readOnly={changePosition !== row._id}
    //         value={positions[row._id]}
    //         onChange={(e) => handlePositionChange(e, row)}
    //         style={{
    //           width: "60px"
    //         }}
    //         autofocus
    //       />
    //       <img
    //         src={changePosition === row._id ? dropdown : editLogo}
    //         alt=""
    //         className="text-left"
    //         onClick={() => {
    //           if (changePosition === row._id) {
    //             onUpdatePosition(row.order, row);
    //           } else {
    //             setChangePosition(row._id);
    //           }
    //         }}
    //         width="24px"
    //         height="24px"
    //       />
    //     </>
    //   ),
    // },
    {
      cell: (row, index) => (
        <>
          {console.log("ind11", row.order)}
          {console.log("wee", row._id)}

          <div className={'customInputEditable'}>
            <input
              type="number"
              readOnly={changePosition !== row._id}
              value={positions[row._id]}
              onChange={(e) => handlePositionChange(e, row)}
              style={{ borderColor: changePosition === row._id && 'green' }}
              // min="1"
              // max="5"
              autofocus
              ref={inputRef}
            />
            <button onClick={() => {
              if (changePosition === row._id) {
                onUpdatePosition(row.order, row);
              } else {
                setChangePosition(row._id);
                inputRef.current.focus();
                // changePosition.current = row._id;
              }
            }} style={{ color: changePosition === row._id && 'green' }}>
              {changePosition === row._id ? <FaCheck /> : <MdModeEditOutline />}
            </button>
          </div>

        </>
      ),
    },
  ];

  useEffect(() => {
    const idOrderMap = {};
    function processFilterItems(filter) {
      filter?.forEach(item => {
        // Log the current item
        console.log(item, "item");

        // Store the order of the current item
        idOrderMap[item._id] = item.order;

        // If the current item has children, recursively process them
        if (item.child.length > 0) {
          processFilterItems(item.child);
        }
      });
    }

    // Call the recursive function with your filter array
    processFilterItems(filter);
    console.log(filter, "ss")

    setPositions(idOrderMap);
    console.log("pos", idOrderMap);
  }, [filter])


  const columns2 = [
    {
      // name: <th style={{ fontSize: '15px', fontWeight: '600' }}>Title</th>,
      selector: "title",
      cell: (row) => <p className="mt-3 para">{`-${row.title}`}</p>,
      sortable: true,
      sortField: "title",
    },
    {
      // name: <th style={{ fontSize: '15px', fontWeight: '600' }}>Date Created</th>,
      selector: "updatedAt",
      cell: (row) => (
        <p className="mt-3 para">
          {moment(row.updatedAt).format("MM-DD-YYYY")}
        </p>
      ),
      sortable: true,
      sortField: "updatedAt",
    },
    {
      // name: <th style={{ fontSize: '15px', fontWeight: '600' }}>Status</th>,
      selector: "Status",
      cell: (d) => {
        return d.status === 1 ? (
          <p className="mt-3 para" style={{ color: "green" }}>
            Active
          </p>
        ) : (
          <p className="mt-3 para" style={{ color: "red" }}>
            In Active
          </p>
        );
      },
    },
    {
      // name: <th style={{ fontSize: '15px', fontWeight: '600' }}>Edit</th>,
      cell: (row) => (
        <img
          src={editLogo}
          alt=""
          onClick={(e) => handleEditClick(e, row._id)}
          width="24px"
          height="24px"
        />
      ),
    },
    {
      // name: <th style={{ fontSize: '15px', fontWeight: '600' }}>Delete</th>,
      cell: (row) => (
        <img
          src={deleteLogo}
          alt=""
          className="text-left "
          onClick={(e) => handleDeleteClick(e, row._id)}
          width="24px"
          height="24px"
        />
      ),
    },

    //Child
    // {
    //   cell: (row, index) => (
    //     <>
    //       {console.log("ind11", row.order)}
    //       {console.log("wee", row._id)}
    //       <input
    //         type="number"
    //         readOnly={changePosition !== row._id}
    //         value={positions[row._id]}
    //         onChange={(e) => handlePositionChange(e, row)}
    //         style={{
    //           width: "60px"
    //         }}
    //         // min="1"
    //         // max="5"
    //         autoFocus
    //       />
    //       <img
    //         src={changePosition === row._id ? dropdown : editLogo}
    //         alt=""
    //         className="text-left"
    //         onClick={() => {
    //           if (changePosition === row._id) {
    //             onUpdatePosition(row.order, row);
    //           } else {
    //             setChangePosition(row._id);
    //           }
    //         }}
    //         width="24px"
    //         height="24px"
    //       />
    //     </>
    //   ),
    // },

    {
      cell: (row, index) => (
        <>
          {console.log("ind11", row.order)}
          {console.log("wee", row._id)}

          <div className={'customInputEditable'}>
            <input
              type="number"
              readOnly={changePosition !== row._id}
              value={positions[row._id]}
              onChange={(e) => handlePositionChange(e, row)}
              style={{ borderColor: changePosition === row._id && 'green' }}
              // min="1"
              // max="5"
              autofocus
              ref={inputRef}
            />
            <button onClick={() => {
              if (changePosition === row._id) {
                onUpdatePosition(row.order, row);
              } else {
                setChangePosition(row._id);
                inputRef.current.focus();

                // changePosition.current = row._id;
              }
            }} style={{ color: changePosition === row._id && 'green' }}>
              {changePosition === row._id ? <FaCheck /> : <MdModeEditOutline />}
            </button>
          </div>

        </>
      ),
    },

  ];

  const columns3 = [
    {
      // name: <th style={{ fontSize: '15px', fontWeight: '600' }}>Title</th>,
      selector: "title",
      cell: (row) => <p className="mt-3 para">{`-${row.title}`}</p>,
      sortable: true,
      sortField: "title",
    },
    {
      // name: <th style={{ fontSize: '15px', fontWeight: '600' }}>Date Created</th>,
      selector: "updatedAt",
      cell: (row) => (
        <p className="mt-3 para">
          {moment(row.updatedAt).format("MM-DD-YYYY")}
        </p>
      ),
      sortable: true,
      sortField: "updatedAt",
    },
    {
      // name: <th style={{ fontSize: '15px', fontWeight: '600' }}>Status</th>,
      selector: "Status",
      cell: (d) => {
        return d.status === 1 ? (
          <p className="mt-3 para" style={{ color: "green" }}>
            Active
          </p>
        ) : (
          <p className="mt-3 para" style={{ color: "red" }}>
            In Active
          </p>
        );
      },
    },
    {
      // name: <th style={{ fontSize: '15px', fontWeight: '600' }}>Edit</th>,
      cell: (row) => (
        <img
          src={editLogo}
          alt=""
          onClick={(e) => handleEditClick(e, row._id)}
          width="24px"
          height="24px"
        />
      ),
    },
    {
      // name: <th style={{ fontSize: '15px', fontWeight: '600' }}>Delete</th>,
      cell: (row) => (
        <img
          src={deleteLogo}
          alt=""
          className="text-left "
          onClick={(e) => handleDeleteClick(e, row._id)}
          width="24px"
          height="24px"
        />
      ),
    },

    //Child
    {
      cell: (row, index) => (
        <>
          {console.log("ind11", row.order)}
          {console.log("wee", row._id)}

          <div className={'customInputEditable'}>
            <input
              type="number"
              readOnly={changePosition !== row._id}
              value={positions[row._id]}
              onChange={(e) => handlePositionChange(e, row)}
              style={{ borderColor: changePosition === row._id && 'green' }}
              // min="1"
              // max="5"
              autofocus
              ref={inputRef}
            />
            <button onClick={() => {
              if (changePosition === row._id) {
                onUpdatePosition(row.order, row);
              } else {
                setChangePosition(row._id);
                inputRef.current.focus();
                // changePosition.current = row._id;
              }
            }} style={{ color: changePosition === row._id && 'green' }}>
              {changePosition === row._id ? <FaCheck /> : <MdModeEditOutline />}
            </button>
          </div>

        </>
      ),
    },
  ];


  const handleRowExpandToggle = (rowStatus, clickedRow) => {
    console.log("isFilter", filter);
    const updatedIndex = filter.map((row, index) => {
      if (row._id === clickedRow._id) {
        return index;
      }
      return null;
    })
    // Filter out null values
    const validIndexes = updatedIndex.filter(index => index !== null);

    // Retrieve the first non-null index
    const firstIndex = validIndexes[0];
    console.log('isIndex', firstIndex)
    filter[firstIndex].defaultExpanded = true;
    setFilter(filter);
    console.log("checktoggle1", rowStatus, clickedRow, filter);
  };

  return (
    <React.Fragment>
      <div className="content mb-5">
        <Row>
          <Col md="12">
            {!loading ? (
              <Card>
                <Row className="align-items-center p-1">
                  <Col md="6" sm="6">
                    <h4 className="ml-4 mt-4">{`${titleList?.find((titl) => titl?.id === props.id)?.name} List`}</h4>
                  </Col>
                  <Col md="6" sm="6">
                    <div className="text-right mt-4 mr-4">
                      <Button label="ADD" onClick={() => handleAddClick()} />
                    </div>
                  </Col>
                </Row>
                {console.log("obj", filter)}
                <CardBody>
                  <div className="text-right  mr-3"></div>
                  <DataTable
                    columns={columns} 
                    data={filter}
                    pagination
                    // defaultSortFieldId={1}
                    highlightOnHover
                    subHeader
                    expandableRowExpanded={row => row.defaultExpanded}
                    expandableRowsComponent={SimpleDataTable}
                    onRowExpandToggled={(row, index) => handleRowExpandToggle(row, index)}
                    // expandableRowsComponentProps={{'child':data.child}}
                    subHeaderComponent={
                      <SearchBox
                        placeholder="Search..."
                        value={search}
                        handleChange={handleChange}
                        className="mt-0 "
                      />
                    }
                    expandableRows
                    customStyles={customStyles}
                  />
                </CardBody>
              </Card>
            ) : (
              <div className="loadercss">
                <ClipLoader color={color} loading={loading} size={50} />{" "}
              </div>
            )}
          </Col>
        </Row>
      </div>
      {show && (
        <ModalC
          message="Are you sure you want to cancel?"
          title="Delete"
          show={true}
          handleClose={handleClose}
          deleteLoader={deleteLoader}
          handleYes={deleteData}
        />
      )}
    </React.Fragment>
  );
}

export default Tables;
