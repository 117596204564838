import React, { useEffect, useState } from "react";
import { API } from "utils/API/API";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import moment from "moment";
import editLogo from "../assets/img/edit.png";
import deleteLogo from "../assets/img/delete.png";
import LinkLogo from "../assets/img/links.png";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Button from "components/Button/Button";
import ModalC from "components/common/ModalC";
import UpdateMangeUser from "components/common/UpdateMangeUser";
import SearchBox from "components/common/SearchBox";

const ManageUsers = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [search, setSearch] = useState();
  const [filter, setFilter] = useState([]);
  const [deleteLoader, setDeleteLoader] = useState(false);

  const history = useHistory();

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    const result = data.filter((dataList) => {
      return dataList?.firstname?.toLowerCase().match(search?.toLowerCase());
    });
    setFilter(result);
  }, [search]);

  const getUserData = async () => {
    setLoading(true);
    try {
      const response = await API.getUserData();
      const data = response.data;
      setData([...data]);
      setFilter([...data]);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleEditClick = (e, id) => {
    e.preventDefault();
    history.push({
      pathname: "/admin/updateuserdetails/",
      search: id,
      state: props.apiPath,
    });
  };

  const ProductdeleteData = async () => {
    setDeleteLoader(true);
    try {
      const response = await API.UserDeleteData(rowId);

      if (response.success) {
        getUserData();
        toast.success(response.message);
      } else toast.error(response.message);
    } catch (e) {
      toast.error("Network Error");
    } finally {
      setDeleteLoader(false);
      setShow(false);
    }
  };

  const handleDeleteClick = (e, id) => {
    e.preventDefault();
    setRowId(id);
    setShow(true);
  };

  const handleProductAdd = () => {
    history.push("/admin/addproductform");
  };

  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.title.toLowerCase();
    const b = rowB.title.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const columns = [
    {
      name: <th style={{ fontSize: "15px", fontWeight: "600" }}>First Name</th>,
      selector: "firstname",
      cell: (row) => (
        <p
          style={{
            fontWeight: "500",
            textTransform: "uppercase",
          }}
          // onClick={()=>history.push(`/admin/manageUserProjects/${row?._id}`)}
          className="mt-3 para"
        >
          {row?.firstname}
        </p>
      ),
      sortable: true,
      sortField: "firstname",
    },

    {
      name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Last Name</th>,
      selector: "manufacturerId",
      cell: (row) => (
        <p className="mt-3 para">
          {row?.lastname === null || row?.lastname === ""
            ? "N/A"
            : row?.lastname}
        </p>
      ),
    },

    {
      name: (
        <th style={{ fontSize: "15px", fontWeight: "600" }}>Email Address</th>
      ),
      cell: (row) => (
        <p className="mt-3 para">
          {row?.email === "" || row?.email === undefined ? "N/A" : row?.email}
        </p>
      ),
    },
    {
      name: (
        <th style={{ fontSize: "15px", fontWeight: "600" }}>Date Created</th>
      ),
      selector: "createdAt",
      cell: (row) => (
        <p className="mt-3 para">
          {moment(row?.createdAt).format("MM-DD-YYYY")}
        </p>
      ),
      sortable: true,
      sortField: "createdAt",
    },
    {
      name: (
        <th style={{ fontSize: "15px", fontWeight: "600" }}>Company Name</th>
      ),
      selector: "companyName",
      cell: (row) => (
        <p className="mt-3 para">
          {row?.companyName === "" || row?.companyName === undefined
            ? "N/A"
            : row?.companyName}
        </p>
      ),
    },
    {
      name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Status</th>,
      selector: "status",
      cell: (row) => {
        return row?.status === 1 ? (
          <p className="mt-3 para" style={{ color: "green" }}>
            Active
          </p>
        ) : (
          <p className="mt-3 para" style={{ color: "red" }}>
            In Active
          </p>
        );
      },
    },

    // {
    //    name: <th style={{ fontSize: '15px', fontWeight: '600' }}>Password</th>,
    //    cell: row => <p className="mt-3 para">{row?.password === "" || row?.password === undefined ? "N/A":row?.password}</p>,
    // },
    {
      name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Edit</th>,
      cell: (row) => (
        <img
          src={editLogo}
          alt=""
          onClick={(e) => handleEditClick(e, row?._id)}
          width="24px"
          height="24px"
        />
      ),
    },
    {
      name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Delete</th>,
      cell: (row) => (
        <img
          src={deleteLogo}
          alt=""
          className="text-left "
          onClick={(e) => handleDeleteClick(e, row?._id)}
          width="24px"
          height="24px"
        />
      ),
    },

    {
      name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Project page</th>,
      cell: (row) => (
        <img
          src={LinkLogo}
          alt=""
          style={{marginLeft:'20px'}}
          className="text-left "  
          onClick={(e) => handleUserProjectRedirect(row?._id)}
          width="24px"
          height="24px"
        />
      ),
    },
  ];
  const handleUserProjectRedirect = async (ids) => {
    let prm = {
      id : ids
    }
    try{
      let response = await API.UserProjects(prm)
      console.log(response)
      if(response?.success){
        // const baseUrl = 'http://localhost:3001/projects';
        const baseUrl = 'http://planspace.ca/projects';

        const queryParams = `${response?.data}`;
        const url = `${baseUrl}?${queryParams}`;
    
        // Open the URL in a new tab
        window.open(url, '_blank');
      }
      else{
        toast.error(response?.message)
      }
    }
    catch(error){
      toast.error("Network error")
    }
  };

  return (
    <React.Fragment>
      <div className="content mb-5">
        <Row>
          <Col md="12">
            {loading ? (
              <div className="loadercss">
                <ClipLoader color="black" loading={loading} size={50} />
              </div>
            ) : (
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="6">
                      <CardTitle tag="h3">Manage Users</CardTitle>
                    </Col>
                    <Col md="6"></Col>
                  </Row>
                </CardHeader>
                <CardBody className="my-4">
                  <DataTable
                    pagination
                    columns={columns}
                    subHeader
                    data={filter}
                    // subHeaderComponent={<SearchBox placeholder="Search..." value={search}
                    // handleChange={handleChange} className="mt-0 "/>}
                  />
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
      {show && (
        <ModalC
          message="Are you sure you want to cancel?"
          title="Delete"
          show={true}
          handleClose={handleClose}
          deleteLoader={deleteLoader}
          handleYes={ProductdeleteData}
        />
      )}
    </React.Fragment>
  );
};
export default ManageUsers;
