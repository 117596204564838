import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import { ClipLoader } from "react-spinners";
import DataTable from "react-data-table-component";
import editLogo from '../assets/img/edit.png';
import deleteLogo from '../assets/img/delete.png';
import Button from 'components/Button/Button';
import { API } from 'utils/API/API';
import { useHistory } from 'react-router-dom';
import ModalC from "components/common/ModalC";
import { toast } from "react-toastify";





const MangeColors = () => {
    const [data, setData] = useState([]);
    const [filter,setFilter] = useState([])
    const [loading, setLoading] = useState(false)
    const [rowId, setRowId] = useState(null)
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);



    useEffect(()=>{
        getColorList()
    },[])


    const getColorList = async () => {
        setLoading(true)
        try{
            const response = await API.fetchColorList()
            setData([...response?.data])
            setFilter([...response?.data])
        }catch(error){
        }
        finally{
            setLoading(false)
        }
    }
    const handleAddClick = () => {
        history.push({
            pathname: '/admin/manageColorForm',
        })
    }
    const handleEditClick = (e, id) => {
        e.preventDefault()
        history.push({
            pathname: '/admin/manageColorForm/',
            search: id,
        })
    }

    const columns = [
        {
            name: <th style={{ fontSize: '15px', fontWeight: '600' }}>Name</th>,
            selector: 'name',
            cell: row => <p className="mt-3 para">{row?.name ? row?.name : 'N/A'}</p>,
            sortable: true,
            sortField: 'name',

        },
        {
            name: <th style={{ fontSize: '15px', fontWeight: '600' }}>Color</th>,
            selector: 'code',
            cell: row => 
            (row?.code2 ? 
            <div style={{ backgroundColor: `${row?.code !== '' ? row.code : ""}` }}   className="dualColorContainerr">
                <div style={{ backgroundColor: `${row?.code !== '' ? row.code : ""}` }} className="colorInner">

                </div>
                <div style={{ backgroundColor: `${row?.code2 !== '' ? row.code2 : ""}` }} className="colorInner2">

                </div>
            </div> :<div className="colorContainerr"
             style={{ backgroundColor: `${row?.code !== '' ? row.code : ""}` }}>
            </div>),
            sortable: false,
            sortField: 'code',

        },
        {
            name: <th style={{ fontSize: '15px', fontWeight: '600' }}>Edit</th>,
            cell: row => <img src={editLogo} alt=""  onClick={(e) => handleEditClick(e, row?._id)} width="24px" height="24px" />,
        },
        {
            name: <th style={{ fontSize: '15px', fontWeight: '600' }}>Delete</th>,
            cell: row => <img src={deleteLogo} alt="" onClick={(e) => handleDeleteClick(e, row?._id)}
             className="text-left " width="24px" height="24px" />
        }
    ]

    const handleDeleteClick = (e,id) =>{
        e.preventDefault()
        setRowId(id)
        setShow(true)
    }
    const deleteRecord = async () =>{
        setDeleteLoader(true)
        try{
            let response = await API.deleteColor(rowId)
            if(response.success){
                getColorList()
                setShow(false)
                toast.success(response.message)
            }
            else{
                toast.error(response.message)
            }
        }catch(error){
            return error
        }
        finally{
            setDeleteLoader(false)
        }
    }

    const handleClose = () => {
        setShow(false)
    }

    return (
        <React.Fragment>
            <div className="content mb-5">
                <Row>
                    <Col md="12">
                        {loading ? <div className="loadercss"><ClipLoader color="black" loading={loading} size={50} /></div> : <Card>
                            <CardHeader>
                                <Row>
                                    <Col md="6">
                                        <CardTitle tag="h3">Manage Colors</CardTitle>
                                    </Col>
                                    <Col md="6">
                                        <div className="text-right mt-4 mr-4">
                                            <Button
                                                label="ADD"
                                                onClick={() => handleAddClick()}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <DataTable pagination
                                    columns={columns}
                                    subHeader
                                    data={filter}
                                />

                            </CardBody>
                        </Card>}
                    </Col>
                </Row>
            </div>
            {show && <ModalC show={true} handleClose={handleClose} deleteLoader={deleteLoader}
                handleYes={deleteRecord}
            />}
        </React.Fragment>
    )

}

export default MangeColors;