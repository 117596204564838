export const RequiredMsg="This field is required."




export const validateEmailRegex= (data)=> {
  let emailRegex=/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailRegex.test(data.toLowerCase())
}


export const ParentCategory=[
  {id:1,name:'Manufacture',value:"Manufacture"},
  {id:2,name:'Segment',value:"Segment"},
  {id:3,name:'Building Type',value:"Building Type"},
  {id:4,name:'Product',value:"Product"},
  {id:5,name:'Schedule',value:"Schedule"},
  {id:6,name:'Product Category',value:"Product Category"},
  {id:7,name:'Schedule Type',value:"Schedule Type"},
]

export const TempChildData=
[
		{
			"_id": "62f3aeab1ed4efc501bb88aa",
			"title": " ds .f d,.sf d,. fd",
			"parentId": null,
			"status": 0,
			"createdAt": "2022-08-10T13:12:11.870Z",
			"updatedAt": "2022-08-10T13:31:16.330Z",
			"__v": 0
		},
		{
			"_id": "62f3aea21ed4efc501bb88a6",
			"title": "w, md, cmdwc dmc ",
			"parentId": null,
			"status": 1,
			"createdAt": "2022-08-10T13:12:02.538Z",
			"updatedAt": "2022-08-10T13:12:58.005Z",
			"__v": 0
		},
		{
			"_id": "62f3aeb81ed4efc501bb88ae",
			"title": "qwertyuioplkjhgfdsazxcvbn",
			"parentId": null,
			"status": 0,
			"createdAt": "2022-08-10T13:12:24.002Z",
			"updatedAt": "2022-08-10T13:12:24.002Z",
			"__v": 0
		},
		{
			"_id": "62f3ae9b1ed4efc501bb88a2",
			"title": "ghjkldn c,s cd",
			"parentId": null,
			"status": 1,
			"createdAt": "2022-08-10T13:11:55.255Z",
			"updatedAt": "2022-08-10T13:11:55.255Z",
			"__v": 0
		},
		{
			"_id": "62f3ae911ed4efc501bb889e",
			"title": "qwerty",
			"parentId": null,
			"status": 0,
			"createdAt": "2022-08-10T13:11:45.306Z",
			"updatedAt": "2022-08-10T13:11:45.306Z",
			"__v": 0
		},
		{
			"_id": "62f3ae891ed4efc501bb889a",
			"title": "ndamcnamcma",
			"parentId": null,
			"status": 1,
			"createdAt": "2022-08-10T13:11:37.235Z",
			"updatedAt": "2022-08-10T13:11:37.235Z",
			"__v": 0
		},
		{
			"_id": "62e7bfc74eb6ffdc25d678c7",
			"title": "First Complaint lorem ispum",
			"parentId": null,
			"status": 1,
			"createdAt": "2022-08-01T11:57:59.594Z",
			"updatedAt": "2022-08-01T11:58:49.388Z",
			"__v": 0
		},
		{
			"_id": "62e7bfcf4eb6ffdc25d678c9",
			"title": "Complaint lorem ispum",
			"parentId": null,
			"status": 1,
			"createdAt": "2022-08-01T11:58:07.926Z",
			"updatedAt": "2022-08-01T11:58:07.926Z",
			"__v": 0
		}
	]


		