import React, { useEffect, useState } from "react";
import { API } from "utils/API/API";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import moment from "moment";
import editLogo from "../assets/img/edit.png";
import deleteLogo from "../assets/img/delete.png";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Button from "components/Button/Button";
import ModalC from "components/common/ModalC";
import SearchBox from "components/common/SearchBox";
import { useTitleContext } from "context/AuthContext/titleLIstContext";
import CSelect from "components/common/CSelect";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Paginations from "components/pagination/pagination";

const AddProductTable = (props) => {
  const [loading, setLoading] = useState(false);
  const [totalCount , setToalCount] = useState(10)
  const [data, setData] = useState();
  const [show, setShow] = useState(false);
  const [statusShow, setStatusShow] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [search, setSearch] = useState();
  const [searchShow, setSearchShow] = useState();
  const [filter, setFilter] = useState([]);
  const [productStatusArr, setProductStatusArr] = useState([]);
  const [titleList, setTitleList] = useState([]);
  const [changeStatus, setChangeStatus] = useState(false);
  const [statusAction, setStatusAction] = useState("");
  const [currentPage , setCurrentPage] = useState(1)
  const { handleSubmit, control, getValues, setValue } = useForm({
    // resolver: yupResolver(schema),
  });
  // const {titleArray} = useTitleContext()

  const history = useHistory();

  useEffect(() => {
    ProductList();
  }, [currentPage , search]);

  useEffect(()=>{
    getListing()
  },[])

  const ProductList = async () => {
    let params = {
      type: "admin",
    };
    setLoading(true);
    try {
      const response = await API.ProductList(params , currentPage , search, 10);
      console.log({response})
      const data = response.data;
      setToalCount(response?.totalCount)
      setData([...data]);
      setFilter([...data]);
      setChangeStatus(false)

    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getListing = async () => {
    try {
      let response = await API.getTitleListing();
      if (response?.success) {
        setTitleList(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      return error;
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleStatusClose = () => {
    setStatusShow(false);
  };

  const handleEditClick = (e, id) => {
    e.preventDefault();
    history.push({
      pathname: "/admin/addproductform/",
      search: id,
      state: props.apiPath,
    });
  };

  const ProductdeleteData = async () => {
    setDeleteLoader(true);
    try {
      const response = await API.ProductdeleteData(rowId);

      if (response.success) {
        // fetchListingData()
        ProductList();
        toast.success(response.message);
      } else toast.error(response.message);
    } catch (e) {
      toast.error("Network Error");
    } finally {
      setDeleteLoader(false);
      setShow(false);
    }
  };

  const handleDeleteClick = (e, id) => {
    e.preventDefault();
    setRowId(id);
    setShow(true);
  };

  // useEffect(() => {
  //   if (search !== undefined) {
  //     const result = data?.filter((dataList) => {
  //       // return dataList?.name?.toLowerCase().match(search.toLowerCase());

  //       if (dataList?.name?.toLowerCase().match(search.toLowerCase())) {
  //         return dataList;
  //       }
  //       else if (
  //         dataList?.modelNumber?.toLowerCase().match(search.toLowerCase())
  //       ) {
  //         return dataList;
  //       }
  //       else if (
  //         dataList?.manufacturerId_data[0]?.title
  //           ?.toLowerCase()
  //           .match(search.toLowerCase())
  //       ) {
  //         return dataList;
  //       } else if (
  //         dataList?.images?.find((me) =>
  //           me?.modelName?.toLowerCase()?.includes(search.toLowerCase())
  //         )
  //       ) {
  //         return dataList;
  //       }
  //     });
  //     setFilter(result);
  //   }
  // }, [search]);

  console?.log(data);

  const handleProductAdd = () => {
    history.push("/admin/addproductform");
  };
  const handleChange = (event) => {
    console.log("gone")
    setSearchShow(event.target.value)
    setTimeout(() => {
      setCurrentPage(1)
      setSearch(event.target.value);

    }, 4000);
  };

  const customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
      // use the selector function to resolve your field names by passing the sort comparitors
      const aField = selector(rowA);
      const bField = selector(rowB);

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }

      return direction === "desc" ? comparison * -1 : comparison;
    });
  };

  const columns = [
    {
      name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Title</th>,
      selector: "name",
      cell: (row) => (
        <div style={{ display: "flex" }}>
          <input
            onChange={() => manageProductStatus(row?._id)}
            style={{ marginRight: "15px" }}
            type="checkbox"
          />
          <p className="mt-3 para">{row.name ? row.name : "N/A"}</p>
        </div>
      ),
      sortable: true,
      sortField: "name",
    },
    titleList?.find((titl) => titl?.id === 1)?.status === 1 && {
      name: (
        <th style={{ fontSize: "15px", fontWeight: "600", width: "1000px" }}>
          {titleList?.find((titl) => titl?.id === 1)?.name}
        </th>
      ),
      selector: "manufacturerId",
      cell: (row) => (
        <p style={{ fontSize: "15px" }}>
          {row?.manufacturerId_data?.length > 0
            ? row?.manufacturerId_data
                ?.map((rowData) => rowData.title)
                .join(", ")
            : "N/A"}
        </p>
      ),
    },
    {
      name: (
        <th style={{ fontSize: "15px", fontWeight: "600" }}>Model Number</th>
      ),
      selector: "images",
      cell: (row) => (
        <p className="mt-3 para">
          {row.images?.length > 0 ? row.images[0]?.modelName : "N/A"}
        </p>
      ),
      // sortable: true,
    },
    {
      name: (
        <th style={{ fontSize: "15px", fontWeight: "600" }}>Date created</th>
      ),
      selector: "createdAt",
      cell: (row) => (
        <p className="mt-3 para">
          {moment(row.createdAt).format("MM-DD-YYYY")}
        </p>
      ),
      sortable: true,
    },
    {
      name: (
        <th style={{ fontSize: "15px", fontWeight: "600" }}>Last updated</th>
      ),
      selector: "updatedAt",
      cell: (row) => (
        <p className="mt-3 para">
          {moment(row.updatedAt).format("MM-DD-YYYY")}
        </p>
      ),
      sortable: true,
    },

    {
      name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Status</th>,
      selector: "status",
      cell: (d) => {
        return d.status === 1 ? (
          <p className="mt-3 para" style={{ color: "green" }}>
            Active
          </p>
        ) : (
          <p className="mt-3 para" style={{ color: "red" }}>
            In Active
          </p>
        );
      },
    },

    {
      name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Edit</th>,
      cell: (row) => (
        <img
          src={editLogo}
          alt=""
          onClick={(e) => handleEditClick(e, row._id)}
          width="24px"
          height="24px"
        />
      ),
    },
    {
      name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Delete</th>,
      cell: (row) => (
        <img
          src={deleteLogo}
          alt=""
          className="text-left "
          onClick={(e) => handleDeleteClick(e, row._id)}
          width="24px"
          height="24px"
        />
      ),
    },
  ];

  const manageProductStatus = (id) => {
    setChangeStatus(true);

    if (productStatusArr?.includes(id)) {
      let result = productStatusArr?.filter((me) => me !== id);
      setProductStatusArr(result);
    } else {
      setProductStatusArr([...productStatusArr, id]);
    }
  };

  console.log(productStatusArr);

  const toggleStatusProduct = async () => {
    setStatusLoader(true);
    let params = {
      id: productStatusArr,
      action: statusAction,
    };
    try {
      let response = await API.changeStatusProduct(params);
      if (response?.success) {
        ProductList();
        setStatusShow(false);
        setProductStatusArr([]);
      }
    } catch (error) {
      toast.error("Network Error");
    } finally {
      setStatusLoader(false);
    }
  };

  return (
    <React.Fragment>
      <div className="content mb-5">
        <Row>
          <Col md="12">
            {loading ? (
              <div className="loadercss">
                <ClipLoader color="black" loading={loading} size={50} />
              </div>
            ) : (
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="6">
                      <CardTitle tag="h3">Product List</CardTitle>
                    </Col>
                    <Col md="6">
                      <div className="text-right mt-4 mr-4">
                        <Button
                          label="ADD"
                          onClick={() => handleProductAdd()}
                        />
                      </div>
                    </Col>
                    {changeStatus && (
                      <div className="statusSelect text-left mt-4 mr-4">
                        <label>Select Status</label>

                        <select
                          onChange={(e) => {
                            setStatusAction(e.target.value);
                            setStatusShow(true);
                          }}
                          name="status"
                          id="status"
                        >
                          <option disabled selected value="">
                            Select status
                          </option>
                          <option value="active">Active</option>
                          <option value="inactive">In-active</option>
                        </select>
                      </div>
                    )}
                  </Row>
                </CardHeader>
                <CardBody className="my-4">
                  <DataTable
                    // pagination
                    columns={columns}
                    data={filter}
                    subHeader
                    subHeaderComponent={
                      <SearchBox
                        title={"Search by Name/Manufacturer/Model no."}
                        placeholder="Search..."
                        value={searchShow}
                        handleChange={handleChange}
                        className="mt-0 "
                      />
                    }
                  />
                  {
                    <Paginations
                      pageSize={10}
                      totalCount={totalCount}
                      currentPage={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  }
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
      {show && (
        <ModalC
          message="Are you sure you want to cancel?"
          title="Delete"
          show={true}
          handleClose={handleClose}
          deleteLoader={deleteLoader}
          handleYes={ProductdeleteData}
        />
      )}

      {statusShow && (
        <ModalC
          message="Are you sure you want to change the status?"
          title="Change status"
          show={true}
          handleClose={handleStatusClose}
          deleteLoader={statusLoader}
          handleYes={toggleStatusProduct}
        />
      )}
    </React.Fragment>
  );
};
export default AddProductTable;
