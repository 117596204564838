import React from "react";
import '../assets/dashboadcenter.scss';
import {
  CardTitle,
  Row,
  Col
} from "reactstrap";


function Dashboard() {
  return (
    <>
      <div className="content">
        <Row className="d-flex align-items-center d-flex justify-content-center">
         
        <Col md="12 ">
          <div className="center">
                <CardTitle tag="h2" className="text-center border-bottom  pb-3 ">Welcome to the Administrator Panel</CardTitle>
                </div>
          </Col>
         
        </Row>
        
      </div>
    </>
  );
}

export default Dashboard;
