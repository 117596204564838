import React from 'react';
import { Col } from 'reactstrap';
import '../assets/loginpage/loginpage.css'
import ForgotPasswordForm from 'views/ForgotPasswordForm';
const ForgotPassword = () => {
    return (
        <React.Fragment>
            <div className='container-fluid' id="main-container">
                <div className='row'>
                    <Col md="3" sm="12"></Col>
                    <Col md="8" mr="auto" className="" sm="12">
                        <div className="loginpage">
                          <ForgotPasswordForm />
                        </div>
                    </Col>
                    <Col md="1" sm="12"></Col>

                </div>
            </div>
        </React.Fragment>
    );
}
export default ForgotPassword;