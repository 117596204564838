import React from 'react';

const SearchBox = props => {

    return (
        <div style={{display:'flex',alignItems:'center'}}>
            <b style={{marginBottom:'0px',marginRight:'10px'}}>{props?.title || "Search by name"}</b>
            <input type="text" placeholder={props.placeholder} value={props.value}
                onChange={props.handleChange} className="mt-0" style={{ width: '220px', height: '35px' }} />

            {/* // <input type="text" placeholder="Search..." value={search} */}
            {/* // onChange={handleChange} className="mt-0 searchinputbox"  style={{backgroundColor:'black'}}/> */}

        </div>

    )
}

export default SearchBox;