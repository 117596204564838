import { CLoader } from 'components/Loader/CLoader';
import React from 'react';
import '../../stylesheets/comman/Button.scss';
const Button = props =>{
    return(
        
        <button type={props.type} className='btn__primary'  onClick={props?.onClick} >
          {props?.isLoading?<CLoader/>:props?.label}
         
        </button>
        
    )
}

export default Button;