import React, { useEffect, useMemo, useRef, useState } from "react";
import { toast } from 'react-toastify';
import { API } from 'utils/API/API';
import './AddDataComponent.css';
import { useLocation } from 'react-router-dom';
import { useParams } from "react-router-dom";
import Button from "components/Button/Button";
import openeye from '../../assets/img/openeye.svg';
import closeeye from '../../assets/img/closeeye.svg';
import '../../stylesheets/manageform.scss';
import { CLoader } from 'components/Loader/CLoader';
// import { browserHistory } from 'react-router'
import { useHistory } from "react-router-dom";
import CSelect from "./CSelect";
import { ParentCategory } from "utils/Constants";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
const { default: InputBox } = require("components/Input/InputBox")

const { Form, Row, Col, FormGroup, Input, Card, CardHeader, CardTitle } = require("reactstrap");


const schema = yup.object({
  title: yup.string().required("This field is required."),
})

const UpdateMangeUser = props => {
  const { handleSubmit, control, setValue, getValues, } = useForm({

  });
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false)
  const endpoint = location.state?.apiPath;
  const level = location.state?.level;
  const id = location?.search?.split("?")[1]




  const updateSingleData = async (data) => {
    setIsLoading(true)
    data['status'] = Number(data?.status)
    try {
      const response = await API.updateSingleUser(id, data);
      if (response.success) {
        toast.success(response.message)
        history.goBack();
      }
      else {
        toast.error(response.message)
      }
    }
    catch (error) {
    }
    finally {
      setIsLoading(false)
    }
  }


  useEffect(() => {
    if (id)
      getData()
  }, [id]);


  const getData = async () => {
    setLoading(true);
    try {
      const response = await API.getSingerUser(endpoint, id);
      if (response.success) {
        const data = response.data
        setValue('status', Number(data?.status))
        setValue('firstname', data?.firstname ? data?.firstname : 'N/A')
        setValue('lastname', data?.lastname ? data?.lastname : 'N/A')
        setValue('email', data?.email);
        setValue('password', data?.password)
        setValue('companyName', data?.companyName ? data?.companyName : 'N/A')
        setValue('industry', data?.industry ? data?.industry : 'N/A')
        setValue('location', data?.location ? data?.location : 'N/A')
        // setChild()
      }
      else {
        toast.error(response.message)
      }
    }
    catch (e) {
    }
    finally {
      setLoading(false);
    }
  }



  const handleCancelClick = (e) => {
    e.preventDefault()
    history.goBack();
  }

  const handleEyeClick = () => {
    if (show) {
      setShow(false)
    } else {
      setShow(true)
    }
  }
 

  const statusList = [
    { id: 1, name: 'Active', value: 1 },
    { id: 2, name: 'In Active', value: 0 }
  ]

  const onError = (errors, e) => console.log(errors, e);

  return (
    <React.Fragment>
      {loading ? <div className="loadercss "><ClipLoader color={'black'} loading={loading} size={50} /> </div> : <div className="content d-flex align-items-center">
        <div className="container">
          <Form onSubmit={handleSubmit(updateSingleData, onError)}>
            <Row >
              <Col md="3"></Col>
              <Col md="6" sm="12">
                <div className="addcomponent">
                  <Card className='p-3'>
                    <CardHeader className="p-0">
                      <CardTitle tag="h5" className='ml-3'>{id !== undefined ? "Edit Record" : "Add Record"}</CardTitle>
                    </CardHeader>

                    <Col className="pr-1" md="12">
                      <Row>
                        <Col md="6">
                          <InputBox
                            placeholder="First Name"
                            type="text"
                            label={'First Name'}
                            name="firstname"
                            control={control}
                          />
                        </Col>
                        <Col md="6">
                          <InputBox
                            placeholder="Last Name"
                            type="text"
                            label={'Last Name'}
                            name="lastname"
                            control={control}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <InputBox
                            placeholder="Company Name"
                            type="text"
                            label={'Company Name'}
                            name="companyName"
                            control={control}
                          />
                        </Col>
                        <Col md="6">
                          <InputBox
                            placeholder="Industry"
                            type="text"
                            label={'Industry'}
                            name="industry"
                            control={control}
                          />
                        </Col>

                      </Row>

                    </Col>
                    <Col className="pr-1" md="12">
                      <FormGroup>
                        <InputBox
                          placeholder="Email Address"
                          type="text"
                          label={'Email Address'}
                          name="email"
                          control={control}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="12">
                      <Row>
                        <Col md="6">
                      <FormGroup>
                        <InputBox
                          placeholder="Location"
                          type="text"
                          label={'Location'}
                          name="location"
                          control={control}
                        />
                      </FormGroup>
                      </Col>
                      <Col md="6">
                      <FormGroup>
                            <CSelect
                              placeholder="Select Status"
                              label="Status"
                              options={statusList}
                              displayName={'name'}
                              value="value"
                              name="status"
                              control={control}
                            />
                          </FormGroup>
                      </Col>
                      </Row>
                    </Col>
                    <Col className="pr-1" md="12 pr-0">
                      <Row>
                        <Col md="6">
                          {/* <div className="container-eye">
                            <div className="center-eye">
                              <div className="image-eye"> */}
                           {/* <span className="ab"><img src={show ? openeye : closeeye} onClick={handleEyeClick} style={{ width: '20px' }} className="image-eye"/></span> */}
{/* 
                              <InputBox
                                placeholder="Password"
                                type={show ? "text" : "password"}
                                label={'Password'}
                                name="password"
                                control={control}
                            
                                className="password-input"
                              /> */}
                            {/* </div>
                            </div>
                          </div> */}
                        </Col>
                        <Col md="6">
                         
                        </Col>


                      </Row>
                    </Col>

                    <Row className="align-items-center my-3 p-3">
                      <Col md="6" sm="6">
                        <div className="">
                          <Button
                            label="SUBMIT"
                            isLoading={isLoading}
                          />
                        </div>
                      </Col>
                      <Col md="6" sm="6">
                        <div className="text-right">
                          <Button
                            label="CANCEL"
                            onClick={handleCancelClick}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </div>
              </Col>
              <Col md="3" ></Col>
            </Row>
          </Form>
        </div>
      </div>}
    </React.Fragment>
  );

}

export default UpdateMangeUser;