import InputBox from 'components/Input/InputBox';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button/Button';
import React from 'react';
import {
    Card,
    CardHeader,
    CardTitle,
    Col,
    Row,
    Form,
} from 'reactstrap';
const ForgotPasswordForm = () => {
    const history = useHistory();

    return (
        <React.Fragment>
            <div className="content">
                <div className="container">
                    <div className="row">
                        <Col md="8">
                            <Card className='p-3'>
                                <CardHeader className="p-0">
                                    <CardTitle tag="h5">Forgot Password</CardTitle>
                                </CardHeader>
                                <Form>
                                    <Row>
                                        <Col md="12">
                                            <InputBox label="Enter Email"
                                                type="email"
                                                placeholder="Email"
                                            />
                                        </Col>
                                        <Col md="12">
                                        <Row className='align-items-center p-1 my-3'>
                                        <Col md="6">
                                            <Button
                                                type="submit"
                                                label="SEND"
                                            />
                                            
                                        </Col>
                                        <Col className='text-right' md="6">
                                            <a className='forgotpasswordlink text-secondary' href='#' onClick={() => history.push('/')}>  Back to Login </a>
                                        </Col>
                                        </Row>
                                        </Col>
                                    </Row>
                                    
                                </Form>
                            </Card>
                        </Col>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}
export default ForgotPasswordForm;