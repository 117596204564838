import InputBox from "components/Input/InputBox";
import React, { useEffect, useState } from "react";
import Button from "components/Button/Button";
import "../assets/scss/editTitle.scss";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import * as yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import CSelect from "components/common/CSelect";
import { API } from "utils/API/API";
import { toast } from "react-toastify";
import { useAuthContext } from "context/AuthContext/AuthContext";

const schema = yup.object({
  name: yup.string().required("This field is required."),
});

const EditTitleForm = () => {
  const history = useHistory();
  const { handleSubmit, control, getValues, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const { SetFilterShow } = useAuthContext()

  const prm = useParams();
  const id = prm.id;
  const stateName = history?.location?.state?.name;
  const stateStatus = history?.location?.state?.status;

  const [loader,setLoader] = useState(false)

  const statusList = [
    { id: 1, name: "Active", value: 1 },
    { id: 2, name: "In Active", value: 0 },
  ];

  const onSubmit = (data) => {
    const param = {
      name: data.name,
      status: parseInt(data.status),
    };

    UpdateTitle(param);
  };

  const UpdateTitle = async (param) => {
    setLoader(true)
    try{
      let response = await API.UpdateTitle(param, id);
      if(response?.success){
        toast.success(response.message)
        history.goBack();
        // window.location.reload();
        SetFilterShow(true)
      }
      else{
        toast.error(response.message)
      }
    }
    catch(error){
      toast.error(error)
    }
    finally{
      setLoader(false)
    }
  };

  const handleCancelClick = (e) => {
    e.preventDefault();
    history.goBack();
  };

  useEffect(() => {
    setValue("name", stateName);
    setValue("status", stateStatus);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <div className="mains">
        <div className="editTitleForm">
          {/* <h2>Edit Title</h2>
        <div className="editTitleFormBox">
          
        </div> */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="addcomponent">
              <Card className="p-3">
                <CardHeader className="p-0">
                  <CardTitle tag="h5" className="ml-3">
                    {"Edit Title"}
                  </CardTitle>
                </CardHeader>

                <Col className="pr-1" md="12">
                  <Row>
                    <Col md="12">
                      <InputBox
                        placeholder="Title name"
                        type="text"
                        label={"Title Name"}
                        name="name"
                        control={control}
                      />
                    </Col>
                    <Col md="12">
                      <CSelect
                        placeholder="Select Status"
                        label="Status"
                        options={statusList}
                        displayName={"name"}
                        value="value"
                        name="status"
                        control={control}
                      />
                    </Col>
                  </Row>
                </Col>

                <Row className="align-items-center my-3 p-3">
                  <Col md="6" sm="6">
                    <div className="">
                      <Button
                        label="SUBMIT"
                        isLoading={loader}
                        type="submit"
                      />
                    </div>
                  </Col>
                  <Col md="6" sm="6">
                    <div className="text-right">
                      <Button label="CANCEL" onClick={handleCancelClick} />
                    </div>
                  </Col>
                </Row>
              </Card>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditTitleForm;
