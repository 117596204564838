import { Controller } from "react-hook-form"

const { FormGroup, Input } = require("reactstrap")

const CSelect = (props) => {


    const {enablePlaceholder, label, placeholder, name, control, options, displayName, value, selectedValue, onChange } = props

    return (
        <>
            <FormGroup>
                <label style={{fontSize:'14px'}}>{label}</label>

                <Controller
                    name={name}
                    control={control}
                    render={({ field }) =>
                        <Input  {...field} type="select" name="select" id="exampleSelectMulti" >
                        {
                            enablePlaceholder ? 
                                <option value={undefined}  selected>{placeholder}</option> :
                                <option disabled selected>{placeholder}</option>
                        }
                            {options && options?.length>0 && options.map(me => (
                                <option key={me.id} value={me[value]}>{me[displayName]}</option>
                            ))}



                        </Input>}
                />

            </FormGroup>
        </>
    )
}

export default CSelect