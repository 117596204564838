import React, { useMemo } from "react";
import './InputBox.scss';
import '../../stylesheets/comman/Button.scss'
import { FormGroup, Input } from "reactstrap";
import { Controller, useFormState } from "react-hook-form";

const InputBox = props => {
    const {label,name,control,type,placeholder,style,value,onChange,rule}=props

    const state = useFormState({ control, name, });
   const isErrorExist = useMemo(() => Boolean(state.errors[name]), [state]);
   const errorMessage = useMemo(() => state.errors[name]?.message || undefined, [state]); 

// console.log({isErrorExist,errorMessage});
// console.log({control});
    return (
        <FormGroup>
            {label ? <label style={{fontSize:'14px'}}>{label}</label> : ""}

            <div className="InputBoxIcon">

                {!!control ?<Controller
                    name={name}
                    rules={rule}
                    control={control}
                    render={({ field }) => <Input
                        {...field}
                        type={type}
                        // value={props?.value}
                        className={style}
                    />}
                />:<Input
                placeholder={placeholder}
                type={type}
                onChange={onChange}
                value={value}
                className={style}
                

            /> }
                
                <button onClick={props.togglePassword}>
                </button>
                {isErrorExist && <span className='form-error'>{errorMessage}</span>}
            </div>
        </FormGroup>
        // )}
        // />
    )
}
export default InputBox;