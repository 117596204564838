import UpdateMangeUser from "components/common/UpdateMangeUser";
import React from "react";

const ManageUserForm = () =>{
return(
<React.Fragment>
  <UpdateMangeUser />
</React.Fragment>
);
}

export default ManageUserForm;
