import React, { useEffect, useState } from "react";
import Select from "react-select";
import './multiSelect.scss'


const ReactSelectMulti = (props) => {
  const [option, setOptions] = useState(props?.data);
  const [selectedOption, setSelectedOptions] = useState([]);

  useEffect(() => {
    setOptions(props?.data);
  }, [props?.data]);

  console.log(selectedOption);
  useEffect(() => {
    props.onProductSelect(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    if (props?.mapProduct !== undefined) ProductMap();
  }, [props?.mapProduct]);

  const ProductMap = () => {
    const result =
      props?.mapProduct &&
      props?.mapProduct?.map((me) => {
        console.log(me)
        return {
          ...me,
          label: `${me?.name} ${ me?.modelNumber  ? '(' + me?.modelNumber  + ')': ''}`,
          value: me._id,
        };
      });
    setSelectedOptions(result);
  };

//   const customSearch = (inputValue) => {
//     setSearchQuery(inputValue);
//     console.log("sadasd", inputValue);

//     let result = props?.data?.filter((dataList) => {
//       if (dataList?.name?.toLowerCase().match(inputValue.toLowerCase())) {
//         console.log("sadasd", "up");

//         return dataList;
//       }
//       if (
//         dataList?.images?.find((me) =>
//           me?.modelName?.toLowerCase()?.includes(inputValue.toLowerCase())
//         )
//       ) {
//         console.log("sadasd", "down");

//         return dataList;
//       }
//     });

//     console.log("sadasd result", result);

//     setOptions(result);
//   };

  const customFilterOption = (option, inputValue) => {

    // console.log(option, inputValue)

    if (!inputValue) return true;
    if (option?.label?.toLowerCase()?.match(inputValue.toLowerCase())) 
      return true;

    else if (option?.data?.modelNumber?.toLowerCase().match(inputValue.toLowerCase())) {
      return true;
    }

    return option.data.images?.some((me) =>
      me?.modelName?.toLowerCase()?.includes(inputValue.toLowerCase())
    );
  };

  return (
      <Select
        isMulti
        //    isSearchable={false}
        classNamePrefix="multiSelectBox"
        value={selectedOption}
        
        onChange={setSelectedOptions}
        options={option}
        //    onInputChange={(inputValue) => customSearch(inputValue)}
        filterOption={customFilterOption}
      />
  );
};

export default ReactSelectMulti;
