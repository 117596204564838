import ModalC from "components/common/ModalC";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { API } from "utils/API/API";



const ManageUserProjects = () => {
  let params = useParams();
  const [loading, setLoading] = useState(false);
  const [userProjects, setUserProjects] = useState([]);

  useEffect(() => {
    getUserProjectList();
  }, []);

  const getUserProjectList = async () => {
    setLoading(true);
    const id = params?.id;
    try {
      let response = await API.getUserProjectApiList(id);
      if(response?.success){
          setUserProjects(response?.data)
          console.log(response?.data)
      }
      else{
          toast.error(response?.message)
      }
    } catch (error) {
      toast.error("Network error");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Name</th>,
      selector: "name",
      cell: (row) => (
        <p
          style={{
            fontWeight: "500",
            textTransform: "uppercase",
          }}
          className="mt-3 para"
        >
          {row?.name}
        </p>
      ),
      sortable: true,
      sortField: "name",
    },

    {
      name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Created At</th>,
      selector: "createdAt",
      cell: (row) => (
        <p className="mt-3 para">
          {row?.createdAt ?  moment(row?.createdAt)?.format("DD-MM-yyyy") : "-"}
        </p>
      ),
      sortable: true,
      sortField: "createdAt",
    },

    {
      name: (
        <th style={{ fontSize: "15px", fontWeight: "600" }}>Zip Code</th>
      ),
      cell: (row) => (
        <p className="mt-3 para">
          {row?.zipCode  ? row?.zipCode : "N/A"}
        </p>
      ),
      sortable: true,
      sortField: "zipCode",
    },
    {
      name: (
        <th style={{ fontSize: "15px", fontWeight: "600" }}>No. of products</th>
      ),
      selector: "createdAt",
      cell: (row) => (
        <p className="mt-3 para">
          {row?.products?.length > 0 ? row?.products?.length : 0}
        </p>
      ),
    },
    // {
    //   name: (
    //     <th style={{ fontSize: "15px", fontWeight: "600" }}>Company Name</th>
    //   ),
    //   selector: "companyName",
    //   cell: (row) => (
    //     <p className="mt-3 para">
    //       {row?.companyName === "" || row?.companyName === undefined
    //         ? "N/A"
    //         : row?.companyName}
    //     </p>
    //   ),
    // },
    // {
    //   name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Status</th>,
    //   selector: "status",
    //   cell: (row) => {
    //     return row?.status === 1 ? (
    //       <p className="mt-3 para" style={{ color: "green" }}>
    //         Active
    //       </p>
    //     ) : (
    //       <p className="mt-3 para" style={{ color: "red" }}>
    //         In Active
    //       </p>
    //     );
    //   },
    // },

    // // {
    // //    name: <th style={{ fontSize: '15px', fontWeight: '600' }}>Password</th>,
    // //    cell: row => <p className="mt-3 para">{row?.password === "" || row?.password === undefined ? "N/A":row?.password}</p>,
    // // },
    // {
    //   name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Edit</th>,
    //   cell: (row) => (
    //     <img
    //       src={editLogo}
    //       alt=""
    //       onClick={(e) => handleEditClick(e, row?._id)}
    //       width="24px"
    //       height="24px"
    //     />
    //   ),
    // },
    // {
    //   name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Delete</th>,
    //   cell: (row) => (
    //     <img
    //       src={deleteLogo}
    //       alt=""
    //       className="text-left "
    //       onClick={(e) => handleDeleteClick(e, row?._id)}
    //       width="24px"
    //       height="24px"
    //     />
    //   ),
    // },
  ];

  return (
    <React.Fragment>
      <div className="content mb-5">
        <Row>
          <Col md="12">
            {loading ? (
              <div className="loadercss">
                <ClipLoader color="black" loading={loading} size={50} />
              </div>
            ) : (
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="6">
                      <CardTitle tag="h3">User Projects</CardTitle>
                    </Col>
                    <Col md="6"></Col>
                  </Row>
                </CardHeader>
                <CardBody className="my-4">
                  <DataTable
                    pagination
                    columns={columns}
                    subHeader
                    data={userProjects}
                    // subHeaderComponent={<SearchBox placeholder="Search..." value={search}
                    // handleChange={handleChange} className="mt-0 "/>}
                  />
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ManageUserProjects;
