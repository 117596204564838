import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import LoginPage from "layouts/LoginPage";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import ForgotPassword from "layouts/ForgotPassword";
import AdminLayout from "layouts/Admin.js";
import { CToaster } from "components/Toaster/CToaster";
import { AuthProvider } from "context/AuthContext/AuthContext";
import GlobalAdd from "views/GlobalAdd";
import PrivateRoute from "components/PrivateRoute/PrivateRoute";
import { useTitleContext } from "context/AuthContext/titleLIstContext";

const App = () => {

  // const {getTitleListing} = useTitleContext()

  // useEffect(()=>{
  //   // getTitleListing()
  // },[])

  return (
    
    <>  
    <CToaster />
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={() => <LoginPage />} />
          <Route path="/forgotpassword" render={() => <ForgotPassword />} />
          <PrivateRoute path="/admin/" render={(props) => <AdminLayout {...props} />} />
        </Switch>
      </BrowserRouter>
    </>

  )
}

export default App