import AxiosInstance from "utils/AxiosProvider/AxiosProvider"

export const getToken = () => {
  const token = localStorage.getItem("mytoken");
  return token;
}



export const API = {

  async login(params) {
    try {
      let response = await AxiosInstance.post("user/login", {
        ...params
      })
      return response.data
    }
    catch (e) {
      console.log('item',e);
      return e.response.data;
    }
  },


  async fetchListingData(endpoint) {
    try {
      let response = await AxiosInstance.get(endpoint, {
        headers: {
          'x-access-token': getToken(),
        }
      });
      return response.data
    }
    catch (e) {
      return e.data
    }
  },

  async getModelNumberList() {
    try {
      let response = await AxiosInstance.get("model-number/list", {
        headers: {
          'x-access-token': getToken(),
        }
      });
      return response.data
    }
    catch (e) {
      return e.data
    }
  },

  async fetchListingDataWithChild(endpoint) {
    try {
      let response = await AxiosInstance.get(`/${endpoint}/hierarchy`, {
        headers: {
          'x-access-token': getToken(),
        }
      });
      return response.data
    }
    catch (e) {
      return e.data
    }
  },

  async getUserProjectApiList(id) {
    try {
      let response = await AxiosInstance.get(`/admin/user/project-list/${id}`, {
        headers: {
          'x-access-token': getToken(),
        }
      });
      return response.data
    }
    catch (e) {
      return e.data
    }
  },

  async ProductList(params , currPage , search , record) {
    try {
      let response = await AxiosInstance.post(`product/list?sort=createdAt&search=${search ? search : ""}&limit=${record}&page=${currPage}`,{...params}, {
        headers: {
          'x-access-token': getToken(),
        }
      });
      return response.data
    }
    catch (e) {
      return e.data
    }
  },
  async updateTitleIndex(params) {
    try {
      let response = await AxiosInstance.put('sidebar-filter/update-level',{...params}, {
        headers: {
          'x-access-token': getToken(),
        }
      });
      return response.data
    }
    catch (e) {
      return e.data
    }
  },

  async getTitleListing() {
    try {
      let response = await AxiosInstance.get('sidebar-filter');
      return response?.data
    }
    catch (e) {
      return e.data
    }
  },

  async UpdateTitle(params,id) {
    try {
      let response = await AxiosInstance.put(`sidebar-filter/${id}`, { ...params }, {
        headers: {
          'x-access-token': getToken()
        }
      })
      return response.data
    }
    catch (e) {
      return e.response.data
    }
  },

  async UpdateField(params,id) {
    try {
      let response = await AxiosInstance.put(`product-key/${id}`, { ...params }, {
        headers: {
          'x-access-token': getToken()
        }
      })
      return response.data
    }
    catch (e) {
      return e.response.data
    }
  },

  async ProductdeleteData(id) {
    try {
      let response = await AxiosInstance.delete(`/product/${id}`, {
        headers: {
          'x-access-token': getToken(),
        }
      });
      return response.data
    }
    catch (e) {
      return e.data
    }
  },
  async UserDeleteData(id) {
    try {
      let response = await AxiosInstance.delete(`/admin/user/${id}`, {
        headers: {
          'x-access-token': getToken(),
        }
      });
      return response.data
    }
    catch (e) {
      return e.data
    }
  },

  async UserProjects(params) {

    try {
      let response = await AxiosInstance.post(`/user/user-projects`, { ...params }, {
        headers: {
          'x-access-token': getToken()
        }
      })
        return response.data
    }
    catch (e) {
      return e.response.data
    }
  },


  async getData(endpoint, id) {
    try {
      let response = await AxiosInstance.get(`/${endpoint}/${id}`, {
        headers: {
          'x-access-token': getToken(),
        }
      });
      return response.data
    }
    catch (e) {
      return e.data
    }
  },
  async getSingerUser(endpoint, id) {
    try {
      let response = await AxiosInstance.get(`/admin/user/${id}`, {
        headers: {
          'x-access-token': getToken(),
        }
      });
      return response.data
    }
    catch (e) {
      return e.data
    }
  },

  async getFieldsName() {
    try {
      let response = await AxiosInstance.get(`product-key`, {
        headers: {
          'x-access-token': getToken(),
        }
      });
      return response.data
    }
    catch (e) {
      return e.data
    }
  },

  async productEditGet(id) {
    try {
      let response = await AxiosInstance.get(`/product/${id}`, {
        headers: {
          'x-access-token': getToken(),
        }
      });
      return response.data
    }
    catch (e) {
      return e.data
    }
  },


  async getUserData() {
    try {
      let response = await AxiosInstance.get(`admin/user`, {
        headers: {
          'x-access-token': getToken(),
        }
      });
      return response.data
    }
    catch (e) {
      return e.data
    }
  },

  async deleteData(endpoint, id) {
    try {

      let response = await AxiosInstance.delete(`/${endpoint}/${id}`, {
        headers: {
          'x-access-token': getToken(),
        }
      });
      return response.data
    }
    catch (e) {
      return e.data
    }
  },


  async AddTableData(endpoint, params) {
    try {
      let response = await AxiosInstance.post(endpoint, { ...params }, {
        headers: {
          'x-access-token': getToken()
        }
      })
      if (response.status === 200) {
        return response.data
      }
      else {
        return response.data

      }
    }
    catch (e) {
      return e.response.data
    }
  },


  async AddUpdate(endpoint, id, params) {
    try {
      let response = await AxiosInstance.post(`/${endpoint}/${id}`, { ...params }, {
        headers: {
          'x-access-token': getToken()
        }
      })
      return response.data
    }
    catch (e) {
      return e.response.data
    }
  },

  async updateSingleUser(id, params) {
    try {
      let response = await AxiosInstance.put(`/admin/user/${id}`, { ...params }, {
        headers: {
          'x-access-token': getToken()
        }
      })
      return response.data
    }
    catch (e) {
      return e.response.data
    }
  },

  async addProduct(params, products , images ,mulFiles,titelObj) {

    console.log("child",mulFiles)

    try {
      let formData = new FormData()
      Object.keys(params).forEach((k) => {
        if (params[k] === undefined || params[k] === null) {
          delete params[k];
        }
        else
          formData.append(k, params[k]);

      });

      Object.keys(titelObj).forEach((k) => {
        console.log("gone",k)

        if (titelObj[k].length === 0) {
          console.log("gone2")

          delete titelObj[k];
        }
        else{
          let vl = []
          titelObj[k].map((title)=>{
            vl.push(title?._id)
          })
          vl.toString()
          console.log(vl)
          formData.append(k, vl);
        }
      });


      let pr =[]
      if(products.length !==0){
        products?.map(p => {
          pr.push(p)
        })
      }
      formData.append("relatedProducts", pr)

      images.map(i=>{
        formData.append("images", i.fileName)
        formData.append("colors", i.id)
        formData.append("modelName", i.modelName)
        formData.append("imageUrl", i.imageUrl)
      })

      mulFiles.map((i,index)=>{
        formData.append("docName", i.docName)
        formData.append("docFile", i.docFile ? i.docFile : "")
        formData.append("linkPath", i.linkPath)
        if(i.docFile !== ""){
          formData.append("docIndex", index)
        }
      })



      formData.append('status', 1);


      let response = await AxiosInstance.post(`/product`, formData, {
        headers: {
          'x-access-token': getToken()
        }
      })
      console.log("item",response)
      if(response.data.success){
        return response.data
      }
      else{
        return response
      }
    }
    catch (e) {
      console.log("item",e)
      return e.response.data
    }
  },

  async updateProduct(id, params, products , images ,oldImages,files ,oldFiles,titelObj , strArr) {
    try {
      let formData = new FormData()
      Object.keys(params).forEach((k) => {

        if (params[k] === undefined || params[k] === null ) {
          delete params[k];
        }
        else
          formData.append(k, params[k]);
      });

      Object.keys(titelObj).forEach((k) => {
        console.log("gone",k)

        if (titelObj[k].length === 0) {
          console.log("gone2")

          delete titelObj[k];
        }
        else{
          let vl = []
          titelObj[k].map((title)=>{
            vl.push(title?._id)
          })
          vl.toString()
          console.log(vl)
          formData.append(k, vl);
        }
      });

      let pr =[]
      if(products.length !==0){
        products?.map(p => {
          pr.push(p)
        })
      }
      formData.append("relatedProducts", pr)

      strArr?.map((me , idx)=>{
        formData.append(`oldModelNumberArr[${idx}]`, me)
      })
      
      images.map(i=>{
        formData.append("images", i.fileName)
        formData.append("colors", i.id ? i.id : i.color) 
        formData.append("modelName", i.modelName)
        formData.append("imageUrl", i.imageUrl)


      })
      oldImages.map((i,idx)=>{
        formData.append(`oldImages[${idx}][fileName]`, i.fileName)
        formData.append(`oldImages[${idx}][originalname]`, i.originalname)
        formData.append(`oldImages[${idx}][color]`, i.id ? i.id : i.color)
        formData.append(`oldImages[${idx}][imageUrl]`, i.imageUrl)
        formData.append(`oldImages[${idx}][modelName]`, i.modelName)
        formData.append(`oldImages[${idx}][id]`, i._id)

      })

      files.map((i,index)=>{
        formData.append("docName", i.docName)
        formData.append("docFile", i.docFile ? i.docFile : "")
        formData.append("linkPath", i.linkPath)
        if(i.docFile !== ""){
          formData.append("docIndex", index)
        }
      })
      let count = 0
      oldFiles.map((i,idx)=>{
        
        if(i.originalname === "" && i.docFile === ""){

          formData.append(`oldFiles[${idx}][docName]`, i.docName)
          formData.append(`oldFiles[${idx}][id]`, i._id)
          formData.append(`oldFiles[${idx}][linkPath]`, i.linkPath)
        }
        else if(i.originalname === "" && i.docFile !== "") {

          formData.append(`old_filesName`, i.docName)
          formData.append(`old_filesId`, i._id)
          formData.append(`old_filesLink`, i.linkPath)
          formData.append(`old_docFiles`, i.docFile)
          formData.append("old_docIndex", count)
          count++
        }
        else{

          formData.append(`oldFiles[${idx}][docName]`, i.docName)
          formData.append(`oldFiles[${idx}][id]`, i._id)
          formData.append(`oldFiles[${idx}][linkPath]`, i.linkPath)
        }
  
        // else if(i.originalname === "" && i.docFile !== ""){
        //   formData.append(`oldDocFiles[${idx}][id]`, i._id)
        //   formData.append(`oldDocFiles[${idx}]`, i._id)
        // }
      })

      formData.append('status', 1);
      let response = await AxiosInstance.post(`/product/${id}`, formData, {
        headers: {
          'x-access-token': getToken()
        }
      })

      if(response.data.success){
        return response.data
      }
      else{
        return response
      }
    
     
      
    }
    catch (e) {
      return e.response.data
    }
  },

  async updateTag(endpoint, id, params) {
    try {
      let response = await AxiosInstance.put(`/${endpoint}/${id}`, { ...params }, {
        headers: {
          'x-access-token': getToken()
        }
      })
      return response.data
    }
    catch (e) {
      return e.response.data
    }
  },


  async deleteFileFromProduct(params) {

    try {
      let response = await AxiosInstance.post(`product/delete-file`, { ...params }, {
        headers: {
          'x-access-token': getToken()
        }
      })
      return response.data
    }
    catch (e) {
      return e.response.data
    }
  },

  async changeStatusProduct(params) {

    try {
      let response = await AxiosInstance.post(`product/update-status`, { ...params }, {
        headers: {
          'x-access-token': getToken()
        }
      })
      return response.data
    }
    catch (e) {
      return e.response.data
    }
  },

  async deleteImageFromProduct(pId,oId) {

    try {
      let response = await AxiosInstance.delete(`product/delete-image/${pId}/${oId}`, {
        headers: {
          'x-access-token': getToken()
        }
      })
      return response.data
    }
    catch (e) {
      return e.response.data
    }
  },

  async fetchColorList(){
    try{
      let response = await AxiosInstance.get(`color`,{
        headers: {
          'x-access-token': getToken()
        }
      })
      return response.data;

    }catch(error){
      return error
    }
  },

  async addColor(params) {
    try{
      let response = await AxiosInstance.post(`color`,{...params},{
        headers: {
          'x-access-token': getToken()
        }
      })
      return response.data;


    }catch(error){
      return error
    }
  },
  async editColorList (params,id){
    try{
      let response = await AxiosInstance.put(`color/${id}`,{...params},{
        headers: {
          'x-access-token': getToken()
        }
      })
      return response.data;


    }catch(error){
      return error
    }

  },
  async deleteColor (id){
    try{
      let response = await AxiosInstance.delete(`color/${id}`,{
        headers: {
          'x-access-token': getToken()
        }
      })
      return response.data;

    }catch(error){
      return error
    }
  } ,
  async getColorDetails (id){
    try{
      let response = await AxiosInstance.get(`color/${id}`,{
        headers: {
          'x-access-token': getToken()
        }
      })
      return response.data;

    }catch(error){
      return error
    }
  },
  async updateFilterOrder(apiPath, filterId, updatedOrder) {
    try {
      let response = await AxiosInstance.put(`/${apiPath}/${filterId}`,
        {
          updatedOrder
        }, {
        headers: {
          'x-access-token': getToken()
          }
      })
      return response.data;

    } catch (error) {
      return error
    }
  },



}
