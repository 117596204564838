import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import "./multiSelect.scss";

const CreatableSelectComponent = (props) => {
  const [option, setOptions] = useState(props?.data);
  const [selectedOption, setSelectedOptions] = useState([]);

  useEffect(() => {
    setOptions(props?.data);
  }, [props?.data]);

  //    console.log(selectedOption);
  //    useEffect(() => {
  //      props.onProductSelect(selectedOption);
  //    }, [selectedOption]);

  useEffect(() => {
    if (props?.mapProduct) ProductMap();
  }, [props?.mapProduct, props?.data]);

  const ProductMap = () => {
    let result =
      props?.mapProduct?.length > 0 &&
      props?.data?.length > 0 &&
      props?.mapProduct?.map((mes) => {
        let obj = props?.data?.find((me) => me?._id === mes);
        console.log({ obj });
        return {
          ...obj,
          label: `${obj?.modelNumber}`,
          value: obj?._id,
        };
      });
    console.log("obj", result);
    setSelectedOptions(result ? result : []);
  };

  const handleChange = (newValue, actionMeta) => {
    setSelectedOptions(newValue);
    props.onProductSelect(newValue);
    //     console.group('Value Changed');
    //     console.log(newValue);
    //     console.log(`action: ${actionMeta.action}`);
    //     console.groupEnd();
  };

  console.log("obj", selectedOption);

  return (
    <CreatableSelect
      isMulti
      isClearable
      classNamePrefix="multiSelectBox"
      onChange={handleChange}
      options={option}
      value={selectedOption ? selectedOption : ""}
    />
  );
};

export default CreatableSelectComponent;
