import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
import { useHistory } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import logo from "../../assets/img/mainlogo.png";
import { useAuthContext } from "context/AuthContext/AuthContext";
import { API } from "utils/API/API";
import { toast } from "react-toastify";

var ps;

function Sidebar(props) {
  const { doLogin, assignToken } = useAuthContext();
  const { SetFilterShow, filterCheck } = useAuthContext();
  const [filter, setFilter] = React.useState([]);

  const [routeState, setRouteState] = useState([]);

  useEffect(() => {
    setRouteState(props.routes);
    getListing();
  }, [filterCheck]);

  const history = useHistory();
  const sidebar = React.useRef();
  const activeRoute = (routeName, apiPath) => {
    if (apiPath === undefined) {
      return props.location.pathname === routeName ? "active" : "";
    } else {
      let domain = apiPath?.split("/")[1];
      return routeName.includes(domain) ? "active" : "";
    }
  };
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });

  const handleLogoClick = () => {
    history.push("/admin/dashboard");
  };

  const onNavItemClick = (route) => { 
      console.log(route,"Sdsd");
    if (route?.name === "Logout") {
      localStorage.clear();
      doLogin(false);
      assignToken(null);
      history.replace("/");
    } else {
      history.push(route.layout + route.path);
    }
  };

  const getListing = async () => {
    try {
      let response = await API.getTitleListing();
      if (response?.success) {
        console.log(response?.data,"sdsd");
        sortFilterByLevel(response?.data);
        setFilter(response?.data);
        SetFilterShow(false);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      return error;
    }
  };

  const sortFilterByLevel = (result) => {
    // let result = data?.sort((a, b) => a.levels - b.levels);
    let arr = props?.routes;
    let slicedArr = arr?.splice(1, 7);
    let arrr = slicedArr?.map((me) => {
      let obj = result?.find((mes) => mes?.key === me?.key);
      return { ...me, levels: obj?.level };
    });

    let sorted = arrr?.sort((a, b) => a.levels - b.levels);
    arr?.splice(1, 0, ...sorted);
  };

  return (
    <div
      className={`sidebar ${props.showSidebar ? "show" : ""}`}
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="logo">
        <a onClick={handleLogoClick} className="simple-text logo-small">
          <div className="logo-img">
            <img src={logo} alt="react-logo" />
          </div>
        </a>
      </div>
      <div className="sidebar-wrapper" ref={sidebar}>
        <Nav>
          {routeState?.map((prop, key) => {
            return (
              <>
                {prop.showOption === true ? (
                  <li
                    className={
                      activeRoute(
                        prop.layout + prop.path,
                        props?.location?.state?.apiPath
                      ) + (prop.pro ? " active-pro" : "")
                    }
                    key={key}
                  >
                    {prop.key === undefined ? (
                      <a
                        onClick={() => onNavItemClick(prop)}
                        className="nav-link"
                        activeClassName="active"
                      >
                        <i className={prop.icon} />
                        <p className="my-1">{prop.name}</p>
                      </a>
                    ) : (
                      filter?.map(
                        (title) =>
                          prop.key === title?.key &&
                          title?.status === 1 && (
                            <a
                              onClick={() => onNavItemClick(prop)}
                              className="nav-link"
                              activeClassName="active"
                            >
                              <i className={prop.icon} />
                              <p className="my-1">{title.name}</p>
                            </a>
                          )
                      )
                    )}
                  </li>
                ) : (
                  ""
                )}
              </>
            );
          })}
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
