import React, { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { API } from "utils/API/API";
import "./AddDataComponent.css";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import Button from "components/Button/Button";
import { CLoader } from "components/Loader/CLoader";
// import { browserHistory } from 'react-router'
import { useHistory } from "react-router-dom";
import CSelect from "./CSelect";
import { ParentCategory } from "utils/Constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
const { default: InputBox } = require("components/Input/InputBox");
const {
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardTitle,
} = require("reactstrap");

const schema = yup.object({
  title: yup.string().required("This field is required."),
});

const AddDataComponent = (props) => {
  const { handleSubmit, control, setValue, getValues, watch, formState } =
    useForm({
      defaultValues: {
        status: 1,
        //  parentId:null,
        childId: undefined,
      },
      resolver: yupResolver(schema),
    });
  const [forceRender, setForceRender] = useState(0);
  const ParentChildWatch = watch(["parentId", "childId"]);
  const parentId = watch("parentId");
  const childId = watch("childId");
  const [parentList, setParentList] = useState([]);
  const location = useLocation();
  // const [newList, setNewList] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const endpoint = location.state?.apiPath;
  const level = location.state?.level;
  const id = location?.search?.split("?")[1]; 

  console.log(level,"sdsd");

  useEffect(() => {
    getParentList();
  }, []);

  const ref = useRef({ changedByCode: false });
  useEffect(() => {
    const { isDirty, name } = formState;
    if (isDirty && !ref.current.changedByCode) {
      if (name === "parentId") {
        setValue("childId", undefined);
        setForceRender((v) => v + 1);
      }
      ref.current.changedByCode = true;
    } else {
      ref.current.changedByCode = false;
    }
  }, [formState, ParentChildWatch]);

  // useEffect(()=>{
  //   if(watchFields && parentList.length){
  //     setChild()
  //   }
  // },[watchFields, parentList])

  // const subscription = watch((value) => {
  //   if (value?.parentId !== undefined) {

  //     setChild()
  //   }
  //   console.log('value is', value)
  //   console.log('parent id', value?.parentId)
  // });

  // const parentId = getValues('parentId')
  const newList = useMemo(() => {
    if (parentId && parentList.length) {
      const childList = parentList.find((x) => x._id === parentId)?.child;
      return childList;
    }
    return [];
  }, [parentId, parentList]);

  const newCatList = useMemo(() => {
    if (childId && newList?.length) {
      const childList = newList?.find((x) => x._id === childId)?.child;
      return childList;
    }
    return [];
  }, [childId, newList]);

  // const setChild = () => {
  //   const newList = parentList.find((x) => x._id === parentId);
  //   const childList = newList?.child;
  //   // setNewList([...childList])
  // };

  // const setSubCat = () => {
  //   const newCatList = newList.find((x) => x._id === parentId);
  //   const subChildList = newCatList?.child;
  //   // setNewList([...childList])
  // };

  const getParentList = async () => {
    try {
      const response = await API.fetchListingDataWithChild(endpoint);
      const data = response.data;
      setParentList([...data]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const addData = async (data) => {
    console.log(data);
    data["status"] = Number(data.status);
    if (id !== undefined) {
      updateData(data);
    } else {
      setIsLoading(true);

      if (
        data?.parentId === "Select Parent" ||
        data?.parentId === "Select Child" ||
        data?.parentId === "Select Sub Category"
      ) {
        data["parentId"] = null;
      }
      if (
        data?.childId === "Select Child" ||
        data?.childId === "Select Child" ||
        data?.childId === "Select Sub Category"
      ) {
        data["childId"] = null;
      }
      if (
        data?.subCatId === "Select Child" ||
        data?.subCatId === "Select Child" ||
        data?.subCatId === "Select Sub Category"
      ) {
        data["subCatId"] = null;
      }
      if (
        data?.parentId &&
        data?.childId !== null &&
        data?.childId !== undefined
      ) {
        data["parentId"] = data["childId"];
      }
      if (
        data?.parentId &&
        data?.subCatId !== null &&
        data?.subCatId !== undefined
      ) {
        data["parentId"] = data["subCatId"];
      }
      try {
        const response = await API.AddTableData(endpoint, data);
        if (response.success) {
          toast.success(response.message);
          history.goBack();
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const updateData = async (data) => {
    console.log({ data });
    setIsLoading(true);
    if (
      data?.parentId === "Select Parent" ||
      data?.parentId === "Select Child" ||
      data?.parentId === "Select Sub Category"
    ) {
      data["parentId"] = null;
    }
    if (
      data?.childId === "Select Child" ||
      data?.childId === "Select Child" ||
      data?.childId === "Select Sub Category"
    ) {
      data["childId"] = null;
    }
    if (
      data?.subCatId === "Select Child" ||
      data?.subCatId === "Select Child" ||
      data?.subCatId === "Select Sub Category"
    ) {
      data["subCatId"] = null;
    }
    if (data?.childId === "Select Child") {
      data["childId"] = null;
    }
    if (
      data?.parentId &&
      data?.childId !== null &&
      data?.childId !== undefined
    ) {
      data["parentId"] = data["childId"];
    }
    if (
      data?.parentId &&
      data?.subCatId !== null &&
      data?.subCatId !== undefined
    ) {
      data["parentId"] = data["subCatId"];
    }

    console.log(data);
    try {
      const response = await API.AddUpdate(endpoint, id, data);
      if (response.success) {
        toast.success(response.message);
        history.goBack();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id && endpoint) getData();
  }, [id, endpoint]);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await API.getData(endpoint, id);
      if (response.success) {
        const data = response.data;
        console.log(data);
        setValue("status", Number(data.status));
        setValue("title", data?.title);
        setValue(
          "parentId",
          data?.super_parent_data !== undefined
            ? data?.super_parent_data?._id
            : data?.parent_data === undefined
            ? data?.parentId_data?._id
            : data?.parent_data?._id
        );
        setValue(
          "childId",
          data?.super_parent_data !== undefined
            ? data?.parent_data?._id
            : data?.parent_data !== undefined
            ? data?.parentId_data?._id
            : null
        );
        setValue(
          "subCatId",
          data?.super_parent_data !== undefined
            ? data?.parentId_data?._id
            : null
        );
        // setChild();
        // setSubCat()
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelClick = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const statusList = [
    { id: 1, name: "Active", value: 1 },
    { id: 2, name: "In Active", value: 0 },
  ];

  return (
    <React.Fragment>
      {loading ? (
        <div className="loadercss ">
          <ClipLoader color={"black"} loading={loading} size={50} />{" "}
        </div>
      ) : (
        <div className="content d-flex align-items-center">
          <div className="container">
            <Form onSubmit={handleSubmit(addData)}>
              <Row>
                <Col md="3"></Col>
                <Col md="6" sm="12">
                  <div className="addcomponent">
                    <Card className="p-3">
                      <CardHeader className="p-0">
                        <CardTitle tag="h5" className="ml-3">
                          {id !== undefined ? "Edit Record" : "Add Record"}
                        </CardTitle>
                      </CardHeader>

                      <Col className="pr-1" md="12">
                        {level === 3 && (
                          <>
                            <Row>
                              <Col md="12">
                                <CSelect
                                  label="Select Parent"
                                  placeholder="Select Parent"
                                  options={parentList}
                                  displayName={"title"}
                                  value="_id"
                                  enablePlaceholder={true}
                                  name="parentId"
                                  control={control}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6">
                                <CSelect
                                  label="Select Child"
                                  placeholder="Select Child"
                                  key={
                                    getValues("parentId") +
                                    getValues("childId") +
                                    forceRender
                                  }
                                  options={newList}
                                  enablePlaceholder={true}
                                  displayName={"title"}
                                  value="_id"
                                  name="childId"
                                  control={control}
                                />
                              </Col>

                              <Col md="6">
                                <CSelect
                                  label="Select Sub Category"
                                  placeholder="Select Sub Category"
                                  options={newCatList}
                                  displayName={"title"}
                                  value="_id"
                                  enablePlaceholder={true}
                                  name="subCatId"
                                  control={control}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                        {level === 2 ? (
                          <Row>
                            <Col md="12">
                              <CSelect
                                label="Select Parent"
                                placeholder="Select Parent"
                                options={parentList}
                                displayName={"title"}
                                enablePlaceholder={true}
                                value="_id"
                                name="parentId"
                                control={control}
                              />
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col className="pr-1" md="12">
                        <FormGroup>
                          <InputBox
                            placeholder="Title"
                            type="text"
                            label={"Title"}
                            name="title"
                            control={control}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="12 pr-0">
                        <FormGroup>
                          <CSelect
                            placeholder="Select Status"
                            label="Status"
                            options={statusList}
                            displayName={"name"}
                            value="value"
                            name="status"
                            control={control}
                          />
                        </FormGroup>
                      </Col>
                      <Row className="align-items-center my-3 p-3">
                        <Col md="6" sm="6">
                          <div className="">
                            <Button label="SUBMIT" isLoading={isLoading} />
                          </div>
                        </Col>
                        <Col md="6" sm="6">
                          <div className="text-right">
                            <Button
                              label="CANCEL"
                              onClick={handleCancelClick}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                </Col>
                <Col md="3"></Col>
              </Row>
            </Form>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default AddDataComponent;
