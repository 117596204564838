import { useAuthContext } from "context/AuthContext/AuthContext";
import { Redirect, Route } from "react-router-dom";

function PrivateRoute({ render: Component, ...rest }) {

const {login,token}=useAuthContext()


    return (
        <Route
        {...rest}
        render={props =>
            login || localStorage.getItem("mytoken")!==null   ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          )
        }
      />
    );
  }
  export default PrivateRoute