
import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, useLocation } from "react-router-dom";
import Sidebar from "components/Sidebar/Sidebar.js";
import switchon from '../assets/img/switchon.png';
import swtichoff from '../assets/img/swtichoff.png';
import routes from "routes.js";
var ps;

function Dashboard(props) {
  const [backgroundColor, setBackgroundColor] = React.useState("black");
  const [activeColor, setActiveColor] = React.useState("info");
  const mainPanel = React.useRef();
  const location = useLocation();
  const [showSidebar, setShowSidebar] = React.useState(false);
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.body.classList.toggle("perfect-scrollbar-on");
      }
    };
  });
  React.useEffect(() => {
    mainPanel.current.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);
  const handleActiveClick = (color) => {
    setActiveColor(color);
  };
  const handleBgClick = (color) => {
    setBackgroundColor(color);
  };

  // Show sidebar function
  const onSidebarToggle = () => {
    setShowSidebar(!showSidebar);
  }  
   console.log(routes,"sdsd");
  return (
    <div className="wrapper">
      <button type="button" className="toggleBtn" onClick={onSidebarToggle}>{showSidebar?<img src={swtichoff} alt=""/>:<img src={switchon} alt=""/>}</button>
      <Sidebar
        {...props}
        routes={routes}
        bgColor={backgroundColor}
        activeColor={activeColor}
        showSidebar={showSidebar}
      />
      {/* <DemoNavbar /> */}
      <div className="main-panel" ref={mainPanel}>
        <Switch>
          {routes.map((prop, key) => {
            return (
              <Route
                path={prop.layout + prop.path}
                // component={prop.component}
                key={key}
                render={(props)=> <prop.component {...props} id={prop.keyId ? prop.keyId : ""} apiPath={`data${prop.endpoint}`}
                 name={prop.name} level={prop.level}/>}
              />
            );
          })}
        </Switch>
      </div>

    </div>
  );
}

export default Dashboard;
