import React from "react";
import ReactDOM from "react-dom/client";
import './index.scss'

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import { AuthProvider } from "context/AuthContext/AuthContext";
import App from "app";

const root = ReactDOM.createRoot(document.getElementById("root"));

 const AuthWrapper=()=> {
return   <AuthProvider><App/></AuthProvider>
}

root.render(
  <AuthWrapper/>
  );

