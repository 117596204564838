import React, { useEffect, useState } from 'react';
import { API } from 'utils/API/API';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from "react-toastify";
import moment from 'moment';
import editLogo from '../assets/img/edit.png';
import deleteLogo from '../assets/img/delete.png';
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import Button from 'components/Button/Button';
import ModalC from 'components/common/ModalC';
import UpdateMangeUser from 'components/common/UpdateMangeUser';
import SearchBox from 'components/common/SearchBox';


const ManageTags = props => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [rowId, setRowId] = useState(null)
    const [search, setSearch] = useState();
    const [filter, setFilter] = useState([]);
    const [deleteLoader, setDeleteLoader] = useState(false);


    const history = useHistory();




    useEffect(() => {
        getTagList()
    }, [])

    useEffect(() => {
        const result = data.filter(dataList => {
            return dataList.name.toLowerCase().match(search.toLowerCase());
        })
        setFilter(result)
    }, [search]);

    const handleAddClick = () => {
        history.push({
            pathname: '/admin/tag',
            state: { "apiPath": props.apiPath.split("/")[1] }

        })
    }

    const getTagList = async () => {
        setLoading(true);
        let endpoint = props?.apiPath.split("/")[1]
        try {
            const response = await API.fetchListingData(endpoint);
            const data = response.data;
            setData([...data])
            setFilter([...data])
        }
        catch (error) {
        }
        finally {
            setLoading(false)
        }
    }

    const handleClose = () => {
        setShow(false)
    }

    const handleEditClick = (e, id) => {
        e.preventDefault()
        history.push({
            pathname: '/admin/tag/',
            search: id,
            state: { "apiPath": props.apiPath.split("/")[1] }

        })
    }

    const deleteRecord = async () => {
        let endpoint = props?.apiPath.split("/")[1]

        setDeleteLoader(true)
        try {
            const response = await API.deleteData(endpoint, rowId);
            if (response.success) {
                getTagList()
                toast.success(response.message)
            }
            else
                toast.error(response.message)
        }
        catch (e) {
            toast.error('Network Error')

        }
        finally {
            setDeleteLoader(false)
            setShow(false)
        }
    }

    const handleDeleteClick = (e, id) => {
        e.preventDefault()
        setRowId(id)
        setShow(true)
    }





    const columns = [
        {
            name: <th style={{ fontSize: '15px', fontWeight: '600' }}>Title</th>,
            selector: 'name',
            cell: row => <p className="mt-3 para">{row?.name ? row?.name : 'N/A'}</p>,
            sortable: true,
            sortField: 'name',

        },
        {
            name: <th style={{ fontSize: '15px', fontWeight: '600' }}>Date Created</th>,
            selector: 'createdAt',
            cell: row => <p className="mt-3 para">{moment(row?.createdAt).format('MM-DD-YYYY')}</p>,
            sortable: true,
            sortField: 'createdAt'

        },

        {
            name: <th style={{ fontSize: '15px', fontWeight: '600' }}>Edit</th>,
            cell: row => <img src={editLogo} alt="" onClick={(e) => handleEditClick(e, row?._id)} width="24px" height="24px" />,
        },
        {
            name: <th style={{ fontSize: '15px', fontWeight: '600' }}>Delete</th>,
            cell: row => <img src={deleteLogo} alt="" className="text-left " onClick={(e) => handleDeleteClick(e, row?._id)} width="24px" height="24px" />
        }
    ]
    const handleChange = (event) => {
        setSearch(event.target.value)
    }

    return (
        <React.Fragment>
            <div className="content mb-5">
                <Row>
                    <Col md="12">
                        {loading ? <div className="loadercss"><ClipLoader color="black" loading={loading} size={50} /></div> : <Card>
                            <CardHeader>
                                <Row>
                                    <Col md="6">
                                        <CardTitle tag="h3">Manage Tags</CardTitle>
                                    </Col>
                                    <Col md="6">
                                        <div className="text-right mt-4 mr-4">
                                            <Button
                                                label="ADD"
                                                onClick={() => handleAddClick()}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody className='my-4'>
                                <DataTable pagination
                                    columns={columns}
                                    subHeader
                                    data={filter}
                                // subHeaderComponent={<SearchBox placeholder="Search..." value={search}
                                //     handleChange={handleChange} className="mt-0 " />}
                                />
                            </CardBody>
                        </Card>}
                    </Col>
                </Row>
            </div>
            {show && <ModalC show={true} handleClose={handleClose} deleteLoader={deleteLoader}
                handleYes={deleteRecord}
            />}
        </React.Fragment>
    )
}
export default ManageTags;