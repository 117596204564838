import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { ClipLoader } from "react-spinners";
import DataTable from "react-data-table-component";
import editLogo from "../assets/img/edit.png";
import deleteLogo from "../assets/img/delete.png";
import Button from "components/Button/Button";
import { API } from "utils/API/API";
import { useHistory } from "react-router-dom";
import ModalC from "components/common/ModalC";
import { toast } from "react-toastify";

const dataForShow = [
  { name: "Field 1", status: true, _id: "12asd" },
  { name: "Field 2", status: true, _id: "1asd2" },
  { name: "Field 3", status: false, _id: "1asd2" },
  { name: "Field 4", status: true, _id: "12" },
  { name: "Field 5", status: false, _id: "1asd2" },
  { name: "Field 6", status: true, _id: "12" },
];

const MangeFields = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowId, setRowId] = useState(null);
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

      useEffect(()=>{
          getFieldList()
      },[])

      const getFieldList = async () => {
          console.log("hg")
          setLoading(true)
          try{
              const response = await API.getFieldsName()
              console.log(response)
              setData([...response?.data])
              setFilter([...response?.data])
          }catch(error){
          }
          finally{
              setLoading(false)
          }
      }
  const handleAddClick = () => {
    //    history.push({
    //        pathname: '/admin/manageColorForm',
    //    })
  };
  const handleEditClick = (e, row) => {
    e.preventDefault();
    history.push(`/admin/manageFieldsForm/${row?._id}` , row);
  };

  const columns = [
    {
      name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Name</th>,
      selector: "title",
      cell: (row) => (
        <p className="mt-3 para">{row?.title ? row?.title : "N/A"}</p>
      ),
      sortable: true,
      sortField: "name",
    },
    {
      name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Status</th>,
      selector: "status",
      cell: (row) => (
        <p className="mt-3 para">
          {row?.status ? (
            <span style={{ color: "green" }}>Active</span>
          ) : (
            <span style={{ color: "red" }}>In-active</span>
          )}
        </p>
      ),
      sortable: false,
      sortField: "code",
    },
    {
      name: <th style={{ fontSize: "15px", fontWeight: "600" }}>Edit</th>,
      cell: (row) => (
        <img
          src={editLogo}
          alt=""
          onClick={(e) => handleEditClick(e, row)}
          width="24px"
          height="24px"
        />
      ),
    },
    //    {
    //        name: <th style={{ fontSize: '15px', fontWeight: '600' }}>Delete</th>,
    //        cell: row => <img src={deleteLogo} alt="" onClick={(e) => handleDeleteClick(e, row?._id)}
    //         className="text-left " width="24px" height="24px" />
    //    }
  ];

  const handleDeleteClick = (e, id) => {
    //    e.preventDefault()
    //    setRowId(id)
    setShow(true);
  };
  const deleteRecord = async () => {};

  const handleClose = () => {
    setShow(false);
  };

  return (
    <React.Fragment>
      <div className="content mb-5">
        <Row>
          <Col md="12">
            {loading ? (
              <div className="loadercss">
                <ClipLoader color="black" loading={loading} size={50} />
              </div>
            ) : (
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="6">
                      <CardTitle tag="h3">Manage Fields</CardTitle>
                    </Col>
                   
                  </Row>
                </CardHeader>
                <CardBody>
                  <DataTable
                    pagination
                    columns={columns}
                    subHeader
                    data={filter}
                  />
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
      {show && (
        <ModalC
          show={true}
          handleClose={handleClose}
          deleteLoader={deleteLoader}
          handleYes={deleteRecord}
        />
      )}
    </React.Fragment>
  );
};

export default MangeFields;
