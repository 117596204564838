import { Controller } from "react-hook-form"

const { FormGroup, Input } = require("reactstrap")

const CMultiSelect = (props) => {


    const { label, placeholder, name, control, options, displayName, value, selectedValue, onChange } = props

    return (
        <>
            <FormGroup>
                <label style={{fontSize:'14px'}}>{label}</label>

                <Controller
                    name={name}
                    control={control}
                    render={({ field }) =>
                        <select multiple type="select" name="select" id="exampleSelectMulti" >
                            <option disabled selected>{placeholder}</option>
                            {options && options?.length>0 && options.map(me => (
                                <option key={me.id} value={me[value]}>{me[displayName]}</option>
                            ))}



                        </select>}
                />

            </FormGroup>
        </>
    )
}

export default CMultiSelect